import React, { useState, useEffect, useReducer, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, Paper } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";
import useTickets from "../../hooks/useTickets";

import SocketTicketsList from "../../context/Socket/Listeners/SocketTicketsList";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
	ticketsListWrapper: {
		borderRadius: "20px",
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderBottom:"hidden",
	},

	ticketsList: {
		borderRadius:"20px",
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
	},

	ticketsListHeader: {
		borderRadius:"20px",
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},

	ticketsCount: {
		borderRadius:"20px",
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},

	noTicketsText: {
		borderRadius:"20px",
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		borderRadius:"20px",
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	noTicketsDiv: {
		borderRadius:"20px",
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));


// 	**********************
// 	** Global Functions **
// 	**********************
const sortPendingTickets = (ticketA, ticketB) => {
	const currentDate = new Date();
	const timeAwaitingTicketA = currentDate - new Date(ticketA.createdAt);
	const timeAwaitingTicketB = currentDate - new Date(ticketB.createdAt);
	return timeAwaitingTicketB - timeAwaitingTicketA;
};

const sortClosedTickets = (ticketA, ticketB) => {
	const timeInAttendanceTicketA = new Date(ticketA.isClosedAt) - new Date(ticketA.withUserAt);
	const timeInAttendanceTicketB = new Date(ticketB.isClosedAt) - new Date(ticketB.withUserAt);
	return timeInAttendanceTicketB - timeInAttendanceTicketA;
};

const sortOpenTickets = (ticketA, ticketB) => {
	const currentDate = new Date();
	const timeInAttendanceTicketA = currentDate - new Date(ticketA.withUserAt);
	const timeInAttendanceTicketB = currentDate - new Date(ticketB.withUserAt);
	return timeInAttendanceTicketB - timeInAttendanceTicketA;
};

//	**************
// 	** Reducers **
// 	**************
const reducer = (state, action) => {
	if (action.type === "LOAD_TICKETS") {
		const newTickets = action.payload;

		newTickets.forEach(ticket => {
			const ticketIndex = state.findIndex(t => t.id === ticket.id);

			if (ticketIndex !== -1) {
				state[ticketIndex] = ticket;
				if (ticket.unreadMessages > 0) { state.push(state.splice(ticketIndex, 1)[0]); }
			}
			else { state.push(ticket); }
		});

		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		
		if (ticketIndex !== -1) {
			state[ticketIndex].unreadMessages = 0;
			state[ticketIndex].isMeMentioned = false;
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET") {
		const ticket = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		}

		else {
			state.push(ticket);

			if (ticket.status === "pending") state.sort(sortPendingTickets);
			if (ticket.userId && ticket.status === "closed") state.sort(sortClosedTickets);
			if (ticket.userId && ticket.status === "open") state.sort(sortOpenTickets);
		}

		return [...state];
	}

	if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
		const ticket = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticket.id);
		
		if (ticketIndex !== -1) {
			state[ticketIndex] = ticket;
		}

		else {
			state.push(ticket);

			if (ticket.status === "pending") state.sort(sortPendingTickets);
			if (ticket.userId && ticket.status === "closed") state.sort(sortClosedTickets);
			if (ticket.userId && ticket.status === "open") state.sort(sortOpenTickets);
		}

		return [...state];
	}

	if (action.type === "DELETE_TICKET") {
		const ticketId = action.payload;
		const ticketIndex = state.findIndex(t => t.id === ticketId);
		if (ticketIndex !== -1) { state.splice(ticketIndex, 1); }
		return [...state];
	}
	
	if (action.type === "RESET") { return []; }
};

// 	***************
// 	** Component **
// 	***************
const TicketsList = (props) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const { 
		filter, isGroup, isChannel, resetAudioPlayer, selectedQueueIds,
		setTabOpen, showAll, status, style, updateCount, selectedUserIds,
		selectedConnectionIds
	} = props;
	const classes = useStyles();
	const [ticketsList, dispatch] = useReducer(reducer, []);
	const { user } = useContext(AuthContext);

	const [pageNumber, setPageNumber] = useState(1);

	const [prioritizeUserSetting, setPrioritizeUserSetting] = useState();
	const [prioritizeUserTimeSetting, setPrioritizeUserTime] = useState();
	const [ticketsDynamicSortingSetting, setTicketsDynamicSortingSetting] = useState("disabled");

	const params = status === "open"
		? {
			pageNumber,
			status,
			showAll,
			queueIds: JSON.stringify(selectedQueueIds),
			isGroup,
			isChannel,
			filter: filter !== "" && filter !== undefined ? filter : "",
			selectedUserIds: selectedUserIds !== undefined ? selectedUserIds : [],
			selectedConnectionIds: selectedConnectionIds !== undefined ? selectedConnectionIds : [],
		}
		: {
			pageNumber,
			status,
			showAll,
			queueIds: JSON.stringify(selectedQueueIds),
			isGroup,
			isChannel,
			filter: filter !== "" && filter !== undefined ? filter : ""
		}
		
	const { tickets, hasMore, loading } = useTickets(params);

	const { getSetting, isLoading } = useGlobalRegisters();



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [
		status, dispatch, showAll, selectedQueueIds,
		filter, selectedUserIds, selectedConnectionIds
	]);

	useEffect(() => {
		if (!status) return;
		dispatch({ type: "LOAD_TICKETS", payload: tickets });
	}, [tickets, status]);

	useEffect(() => {
		if (status === "pending") { 
			const pendingTickets = ticketsList.filter(ticket => ticket.status === "pending");
			const sumUnreadMessages = pendingTickets.reduce((total, current) => total + current.unreadMessages, 0);
			document.title = sumUnreadMessages > 0  ? `(${sumUnreadMessages}) BestZap` : document.title = "BestZap";
		}
	});

	useEffect(() => {
		if (isLoading) return;

		setPrioritizeUserSetting(getSetting("prioritizeUser"));
		setTicketsDynamicSortingSetting(getSetting("ticketsDynamicSorting"));

		const prioritizeUserTimeResponse = getSetting("prioritizeUserTime");
		if (prioritizeUserTimeResponse === "infinity") setPrioritizeUserTime(prioritizeUserTimeResponse);
		else setPrioritizeUserTime(parseInt(prioritizeUserTimeResponse, 10));
	}, [getSetting, isLoading]);

	useEffect(() => {
		//
		// ***---- Groups ----***
		//
		if (typeof updateCount === "function" && status === "group") {
			const count = ticketsList.filter(ticket => {
				if (ticket.type === 0) return true;
				return ticket.ticketUsers.some(ticketUser => ticketUser.userId === user.id)
			}).length;

			updateCount(count);

			return;
		}

		//
		// ***---- Channels, Open and Closed Tickets ----***
		//
		if (typeof updateCount === "function" && !["group", "pending"].includes(status)) {
			updateCount(ticketsList.length);
			return;
		}

		//
		//	***---- Pending Tickets ----***
		//
		if (typeof updateCount === "function" && status === "pending") {
			if (prioritizeUserSetting === "enabled") {
				let pendingCounter = 0;

				let whatsappTicketsCount = 0;
				const noteTicketsCount = ticketsList.filter(ticket => ticket.type === 1).length;
				const botTicketsCount = ticketsList.filter(ticket => ticket.type === 2).length;
				const internalChatTicketsCount = ticketsList.filter(ticket => ticket.type === 3).length;

				ticketsList.filter(ticket => ticket.type === 0).forEach(ticket => {
					let userExists;
	
					try {
						userExists = ticket?.contact?.users.length === 0 
							? true
							: ticket?.contact?.users.some(contactUser => contactUser.id === user.id);
					} catch (error) {
						userExists = [];
					}

					pendingCounter = !(
							(prioritizeUserTimeSetting !== "infinity" && prioritizeUserTimeSetting * 60 * 1000 < (new Date() - new Date(ticket.createdAt)))
							|| (ticket?.type === 1 || ticket?.type === 2 || (ticket?.type === 0 && userExists))
						)
							? pendingCounter + 1
							: pendingCounter;

					if (ticket.whatsapp && !ticket.whatsapp.waitForBotInteraction) whatsappTicketsCount += 1;
					else if (user.profile === "admin") whatsappTicketsCount += 1;
					else if (ticket.contact.isBot || ticket.isBotInteractionFinished) whatsappTicketsCount += 1;
				});

				updateCount(noteTicketsCount + botTicketsCount + internalChatTicketsCount + whatsappTicketsCount - pendingCounter);
			}
			
			else {
				let whatsappTicketsCount = 0;
				const noteTicketsCount = ticketsList.filter(ticket => ticket.type === 1).length;
				const botTicketsCount = ticketsList.filter(ticket => ticket.type === 2).length;
				const internalChatTicketsCount = ticketsList.filter(ticket => ticket.type === 3).length;

				ticketsList.filter(ticket => ticket.type === 0).forEach(ticket => {
					if (ticket.whatsapp && !ticket.whatsapp.waitForBotInteraction) whatsappTicketsCount += 1;
					else if (user.profile === "admin") whatsappTicketsCount += 1;
					else if (ticket.contact.isBot || ticket.isBotInteractionFinished) whatsappTicketsCount += 1;
				});

				updateCount(noteTicketsCount + botTicketsCount + internalChatTicketsCount + whatsappTicketsCount);
			}

			return;
		}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);



	// 	***************
	// 	** Functions **
	// 	***************
	const loadMore = () => { setPageNumber(prevState => prevState + 1); };

	const handleScroll = (event) => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			event.currentTarget.scrollTop = scrollTop - 100;
			loadMore();
		}
	};



	// 	************
	//  ** Return **
	// 	************
	return (
    <Paper elevation={0} className={classes.ticketsListWrapper} style={style}>
			<SocketTicketsList
				dispatch={dispatch}
				filter={filter}
				selectedQueueIds={selectedQueueIds}
				selectedUserIds={selectedUserIds}
				selectedConnectionIds={selectedConnectionIds}
				status={status}
			/>

			<Paper
				square
				name="closed"
				elevation={0}
				className={classes.ticketsList}
				onScroll={handleScroll}
			>
				<List style={{ paddingTop: 0 }}>
					{ticketsList.length === 0 && !loading ? (
						<div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>{i18n.t("ticketsList.noTicketsTitle")}</span>
							<p className={classes.noTicketsText}>{i18n.t("ticketsList.noTicketsMessage")}</p>
						</div>
					) : (
						<>
							{/* 
								*******************
								** Group Tickets **
								*******************

								// - always follow dynamic sorting;

							*/}
							{status === "group" && ticketsList.sort((ticketA, ticketB) => {
								const updatedDateTicketA = new Date(ticketA.updatedAt);
								const updatedDateTicketB = new Date(ticketB.updatedAt);
								return updatedDateTicketB - updatedDateTicketA;
							}).filter(ticket => {
								if (ticket.type === 0) return true;
								return ticket.ticketUsers.some(ticketUser => ticketUser.userId === user.id);
							}).map(ticket => (
								<TicketListItem
									resetAudioPlayer={resetAudioPlayer}
									ticket={ticket}
									ticketsList={ticketsList}
									key={ticket.id}
									setTabOpen={setTabOpen}
								/>
							))}



							{/* 
								**************************************
								** Channel, Open and Closed Tickets **
								**************************************

								// - sorts tickets when dynamic sorting is enabled;

							*/}
							{["channel", "closed", "open"].includes(status)
							&& ticketsDynamicSortingSetting === "enabled"
							&& ticketsList.sort((ticketA, ticketB) => {
								const updatedDateTicketA = new Date(ticketA.updatedAt);
								const updatedDateTicketB = new Date(ticketB.updatedAt);
								return updatedDateTicketB - updatedDateTicketA;
							}).map(ticket => (
								<TicketListItem
									resetAudioPlayer={resetAudioPlayer}
									ticket={ticket}
									ticketsList={ticketsList}
									key={ticket.id}
									setTabOpen={setTabOpen}
								/>
							))}



							{/* 
								**************************************
								** Channel, Open and Closed Tickets **
								**************************************

								// - does not sort tickets when dynamic sorting is disabled;

							*/}
							{status === "channel"
								&& ticketsDynamicSortingSetting === "disabled"
								&& ticketsList.map(ticket => (
								<TicketListItem
									resetAudioPlayer={resetAudioPlayer}
									ticket={ticket}
									ticketsList={ticketsList}
									key={ticket.id}
									setTabOpen={setTabOpen}
								/>
							))}

							{status === "open"
								&& ticketsDynamicSortingSetting === "disabled"
								&& ticketsList.sort(sortOpenTickets).map(ticket => (
								<TicketListItem
									resetAudioPlayer={resetAudioPlayer}
									ticket={ticket}
									ticketsList={ticketsList}
									key={ticket.id}
									setTabOpen={setTabOpen}
								/>
							))}

							{status === "closed"
								&& ticketsDynamicSortingSetting === "disabled"
								&& ticketsList.sort(sortClosedTickets).map(ticket => (
								<TicketListItem
									resetAudioPlayer={resetAudioPlayer}
									ticket={ticket}
									ticketsList={ticketsList}
									key={ticket.id}
									setTabOpen={setTabOpen}
								/>
							))}




							{/* 
								*********************
								** Pending Tickets **
								*********************

								// - sorts tickets when dynamic sorting is enabled;

							*/}
							{status === "pending"
							&& ticketsDynamicSortingSetting === "enabled"
							&& ticketsList.sort((ticketA, ticketB) => {
								const updatedDateTicketA = new Date(ticketA.updatedAt);
								const updatedDateTicketB = new Date(ticketB.updatedAt);
								return updatedDateTicketB - updatedDateTicketA;
							}).map(ticket => (
								<>
									{/* 
										***---- Notes and Bots ----***
									*/}
									{ticket.type !== 0 && (
										<TicketListItem
											resetAudioPlayer={resetAudioPlayer}
											ticket={ticket}
											ticketsList={ticketsList}
											key={ticket.id}
											setTabOpen={setTabOpen}
										/>
									)}



									{/* 
										***---- WhatsApps ----***

										- Tickets are always visible when:
											\ whatsapp is not enabled to wait for bot interaction;

									*/}
									{ticket.type === 0
									&& ticket.whatsapp
									&& !ticket.whatsapp.waitForBotInteraction
									&& (
										<TicketListItem
											resetAudioPlayer={resetAudioPlayer}
											ticket={ticket}
											ticketsList={ticketsList}
											key={ticket.id}
											setTabOpen={setTabOpen}
										/>
									)}



									{/* 
										***---- WhatsApps ----***

										- Tickets are visible when:
											\ whatsapp is enabled for bot interaction;
											\ AND user profile is admin;

									*/}
									{ticket.type === 0
									&& ticket.whatsapp
									&& ticket.whatsapp.waitForBotInteraction
									&& user.profile === "admin"
									&& (
										<TicketListItem
											resetAudioPlayer={resetAudioPlayer}
											ticket={ticket}
											ticketsList={ticketsList}
											key={ticket.id}
											setTabOpen={setTabOpen}
										/>
									)}



									{/* 
										***---- WhatsApps ----***

										- Tickets are visible when:
											\ whatsapp is enabled for bot interaction;
											\ AND user profile is not admin;
											\ AND (contact is bot (set to don't receive automatic messages);
											\ OR bot interaction on ticket is finished);

									*/}
									{ticket.type === 0
									&& ticket.whatsapp
									&& ticket.whatsapp.waitForBotInteraction
									&& user.profile === "user"
									&& (
											ticket.contact.isBot
									 	|| ticket.isBotInteractionFinished
									)
									&& (
										<TicketListItem
											resetAudioPlayer={resetAudioPlayer}
											ticket={ticket}
											ticketsList={ticketsList}
											key={ticket.id}
											setTabOpen={setTabOpen}
										/>
									)}
								</>
							))}



							{/* 
								*********************
								** Pending Tickets **
								*********************

								// - does not sort tickets when dynamic sorting is disabled;

							*/}
							{status === "pending"
							&& ticketsDynamicSortingSetting === "disabled"
							&& ticketsList.sort(sortPendingTickets).map(ticket => (
								<>
									{/* 
										***---- Notes and Bots ----***
									*/}
									{ticket.type !== 0 && (
										<TicketListItem
											resetAudioPlayer={resetAudioPlayer}
											ticket={ticket}
											ticketsList={ticketsList}
											key={ticket.id}
											setTabOpen={setTabOpen}
										/>
									)}



									{/* 
										***---- WhatsApps ----***

										- Tickets are always visible when:
											\ whatsapp is not enabled to wait for bot interaction;

									*/}
									{ticket.type === 0
									&& ticket.whatsapp
									&& !ticket.whatsapp.waitForBotInteraction
									&& (
										<TicketListItem
											resetAudioPlayer={resetAudioPlayer}
											ticket={ticket}
											ticketsList={ticketsList}
											key={ticket.id}
											setTabOpen={setTabOpen}
										/>
									)}



									{/* 
										***---- WhatsApps ----***

										- Tickets are visible when:
											\ whatsapp is enabled for bot interaction;
											\ AND user profile is admin;

									*/}
									{ticket.type === 0
									&& ticket.whatsapp
									&& ticket.whatsapp.waitForBotInteraction
									&& user.profile === "admin"
									&& (
										<TicketListItem
											resetAudioPlayer={resetAudioPlayer}
											ticket={ticket}
											ticketsList={ticketsList}
											key={ticket.id}
											setTabOpen={setTabOpen}
										/>
									)}



									{/* 
										***---- WhatsApps ----***

										- Tickets are visible when:
											\ whatsapp is enabled for bot interaction;
											\ AND user profile is not admin;
											\ AND (contact is bot (set to don't receive automatic messages);
											\ OR bot interaction on ticket is finished);

									*/}
									{ticket.type === 0
									&& ticket.whatsapp
									&& ticket.whatsapp.waitForBotInteraction
									&& user.profile === "user"
									&& (
											ticket.contact.isBot
										|| ticket.isBotInteractionFinished
									)
									&& (
										<TicketListItem
											resetAudioPlayer={resetAudioPlayer}
											ticket={ticket}
											ticketsList={ticketsList}
											key={ticket.id}
											setTabOpen={setTabOpen}
										/>
									)}
								</>
							))}
						</>
					)}
					{loading && <TicketsListSkeleton />}
				</List>
			</Paper>
    </Paper>
	);
};

export default TicketsList;