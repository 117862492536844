import React, { createContext, useContext, useEffect, useState } from "react";

import api from "../../services/api";
import Mutex from "./Mutex";
import toastError from "../../errors/toastError";

//  **************
//  ** Contexts **
//  **************
const GlobalRegistersContext = createContext();

const useGlobalRegisters = () => useContext(GlobalRegistersContext);

//  ***************
//  ** Providers **
//  ***************
const GlobalRegistersProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************

  // ***---- Flags ----***
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  // ***---- Global Variables ----***
  const [allSettings, setAllSettings] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allQuickAnswers, setAllQuickAnswers] = useState([]);
  const [allQueues, setAllQueues] = useState([]);
  const [allSubqueues, setAllSubqueues] = useState([]);
  const [allWhatsapps, setAllWhatsapps] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allLabels, setAllLabels] = useState([]);
  const [whatsapps, setWhatsapps] = useState([]);
  const [userQueues, setUserQueues] = useState([]);

  const [importMessages, setImportMessages] = useState("disabled");

  const [userQueuesIdsMap, setUserQueuesIdsMap] = useState(new Map());
  const [whatsappQueuesMap, setWhatsappQueuesMap] = useState(new Map());
  const [whatsappPriorizationMap, setWhatsappPriorizationMap] = useState(new Map());

  // ***---- Mutexes ----***
  const mutexUserQueuesIds = Mutex.getInstancia("mutexUserQueuesIds");
  const mutexWhatsappQueues = Mutex.getInstancia("mutexWhatsappQueues");
  const mutexWhatsappPriorization = Mutex.getInstancia("mutexWhatsappPriorization");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (!isAuthorized) return;

    const fetchGlobalRegisters = async () => {
      try {
        const apiCalls = [
          api.get("/settings"), api.get("/AllUsers"),
          api.get("/allQuickAnswers"), api.get("/queue"),
          api.get("/subqueue"), api.get("/AllWhatsapps"),
          api.get("/category"), api.get("/label"),

          api.get("/whatsapp"), api.get("/userQueues"),
          api.get("/importMessages")
        ];

        const [
          SettingsResponse, AllUsersResponse,
          QuickAnswersResponse, QueuesResponse,
          SubqueuesResponse, WhatsappsResponse,
          CategoriesResponse, LabelsResponse,

          WhatsappsResponse2, UserQueuesResponse,
          ImportMessagesResponse
        ] = await Promise.all(apiCalls);

        setAllSettings(SettingsResponse.data);
        setAllUsers(AllUsersResponse.data.users);
        setAllQuickAnswers(QuickAnswersResponse.data.quickAnswers);
        setAllQueues(QueuesResponse.data);
        setAllSubqueues(SubqueuesResponse.data);
        setAllWhatsapps(WhatsappsResponse.data);
        setAllCategories(CategoriesResponse.data);
        setAllLabels(LabelsResponse.data);

        setWhatsapps(WhatsappsResponse2.data);
        setUserQueues(UserQueuesResponse.data);
        setImportMessages(ImportMessagesResponse.data.importMessages);

        setIsLoading(false);
      } catch (exception) {
        setIsLoading(false);
        console.log("- Global Registers - Use Effect Exception:", exception);
        toastError(exception);
      }
    }

    fetchGlobalRegisters();    
  }, [isAuthorized]);



  //  ***************
  //  ** Functions **
  //  ***************

  // ***---- Getters ----***
  const getSetting = (key) => {  
    const { value } = allSettings.find(setting => setting.key === key);
    return value;
	};

  const getUserQueuesIds = async (userId) => {
    if (!userId) return [];

    await mutexUserQueuesIds.lock();

    try {
      const queues = userQueuesIdsMap.get(userId);  
      if (queues) return queues;
  
      const { data } = await api.get(`/userQueuesIds/${userId}`);
      userQueuesIdsMap.set(userId, data);
      return data;
    }
    
    finally {
      await mutexUserQueuesIds.unlock();
    }
  };

  const getWhatsappQueues = async (whatsappId) => {
    if (!whatsappId) return [];
    
    await mutexWhatsappQueues.lock();

    try {
      const queues = whatsappQueuesMap.get(whatsappId);  
      if (queues) return queues;
  
      const { data } = await api.get(`/whatsappQueues/${whatsappId}`);
      whatsappQueuesMap.set(whatsappId, data);
      return data;
    }
    
    finally {
      await mutexWhatsappQueues.unlock();
    }
  };

  const getWhatsappPriorization = async (userId) => {
    if (!userId) return [];

    await mutexWhatsappPriorization.lock();

    try {
      const priorizations = whatsappPriorizationMap.get(userId);
      if (priorizations) return priorizations;

      const { data } = await api.get(`/whatsappPriorization/${userId}`);
      whatsappPriorizationMap.set(userId, data);
      return data;
    }
    
    finally {
      await mutexWhatsappPriorization.unlock();
    }
  };

  // ***---- Updaters ----***
  const updateAllUsers = async () => {
    const apiCalls = [api.get("/AllUsers"), api.get("/userQueues")];
    const [AllUsersResponse, UserQueuesResponse] = await Promise.all(apiCalls);

    userQueuesIdsMap.clear();
    whatsappPriorizationMap.clear();
    setAllUsers(AllUsersResponse.data.users);
    setUserQueues(UserQueuesResponse.data);
  };

  const updateAllQuickAnswers = async () => {
    const { data } = await api.get("/allQuickAnswers");
    setAllQuickAnswers(data.quickAnswers);
  };

  const updateAllQueues = async () => {
    const { data } = await api.get("/queue");
    setAllQueues(data);
  };

  const updateAllSubqueues = async () => {
    const { data } = await api.get("/subqueue");
    setAllSubqueues(data);
  };

  const updateAllCategories = async () => {
    const { data } = await api.get("/category");
    setAllCategories(data);
  };

  const updateAllLabels = async () => {
    const { data } = await api.get("/label");
    setAllLabels(data);
  };

  const updateAllWhatsapps = async () => {
    const apiCalls = [api.get("/AllWhatsapps"), api.get("/whatsapp")];
    const [AllWhatsappsResponse, WhatsappResponse] = await Promise.all(apiCalls);

    whatsappQueuesMap.clear();
    setAllWhatsapps(AllWhatsappsResponse.data);
    setWhatsapps(WhatsappResponse.data);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <GlobalRegistersContext.Provider
      value={{
        isLoading, allUsers, allQuickAnswers,
        allQueues, allSubqueues, allWhatsapps,
        allCategories, allLabels, whatsapps,
        userQueues, importMessages,

        setIsAuthorized, setAllSettings,

        getSetting, getUserQueuesIds, getWhatsappQueues,
        getWhatsappPriorization,

        updateAllUsers, updateAllQuickAnswers, updateAllQueues,
        updateAllSubqueues, updateAllCategories, updateAllLabels,
        updateAllWhatsapps
      }}
    >
      {children}
    </GlobalRegistersContext.Provider>
  );
};

export { useGlobalRegisters, GlobalRegistersProvider };
