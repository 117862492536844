import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
	chips: { display: "flex", flexWrap: "wrap", },

	chip: {
		margin: 2,
		border: `2px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,
	},

	chipCheckbox: {
		color: theme.palette.primary.main,
		'&.Mui-checked': { color: theme.palette.primary.main, }
	},

	selectInputField: {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary, },
    "& .MuiSvgIcon-root": { color: theme.palette.text.primary, },
	},
}));

const SubqueueSelect = ({ selectedSubqueueIds, onChange, subqueuesOptions }) => {
	// 	***************
	// 	** Variables **
	// 	***************
  const classes = useStyles();

	const { allSubqueues } = useGlobalRegisters();



	// 	***************
	// 	** Functions **
	// 	***************
	const handleChange = event => { onChange(event.target.value); };



	// 	************
	// 	** Return **
	// 	************
  return (
		<div style={{ marginTop: 6 }}>
			<FormControl 
				fullWidth
				margin="dense"
				variant="outlined"
				classes={{ root: classes.selectInputField }}
			>
				<InputLabel>{i18n.t("subqueueSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					label={i18n.t("subqueueSelect.inputLabel")}
					value={selectedSubqueueIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: { vertical: "bottom", horizontal: "left", },
						transformOrigin: { vertical: "top", horizontal: "left", },
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const subqueue = allSubqueues.find(s => s.id === id);
									return subqueue
										? (<Chip key={id} variant="outlined" label={subqueue.name} className={classes.chip} />)
										: null;
								})}
						</div>
					)}
				>
					{subqueuesOptions.map(subqueue => (
						<MenuItem key={subqueue.id} value={subqueue.id}>
							<Checkbox className={classes.chipCheckbox} checked={selectedSubqueueIds.indexOf(subqueue.id) > -1} />
              <ListItemText primary={subqueue.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default SubqueueSelect;