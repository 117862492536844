class Mutex {
  static instances = {};

  queue;
  locked;
  identifier;

  constructor(identifier) {
    this.queue = [];
    this.locked = false;
    this.identifier = identifier;
  }

  static getInstancia(identifier) {
    if (!Mutex.instances[identifier]) Mutex.instances[identifier] = new Mutex(identifier);
    return Mutex.instances[identifier];
  }

  lock() {
    return new Promise((resolve) => {
      const tryLock = () => {
        if (!this.locked) {
          this.locked = true;
          resolve();
        }

        else this.queue.push(tryLock);
      }

      tryLock();
    })
  }

  unlock() {
    this.locked = false;
    const nextResolve = this.queue.shift();
    if (nextResolve) nextResolve();
  }
}

export default Mutex;
