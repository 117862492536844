import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from "@material-ui/core/styles";
import {
	Button,
	Container,
	IconButton,
	Grid,
	MenuItem,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { PrintOutlined, Search, VisibilityOutlined } from "@material-ui/icons";

import { calculateSimpleDescribe } from "../../utils/statistics/calculateSimpleDescribe";
import { documentGenerator, tableGenerator } from "../../utils/reports/generators";
import { encodeEmptyStringDataToMissing, formatDate } from "../../utils/reports/dataDisplays";
import { exportToNewWindow } from "../../utils/reports/exports";
import { header, footer, alignHeader, alignFooter } from "../../utils/reports/structure";
import { reportOptions } from "../../utils/reports/objects";
import { writeStatisticsCaption } from "../../utils/reports/writers";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import botChatIcon from "../../assets/ticketsTypes/bot.svg";
import CategorySelect from "../../components/CategorySelect";
import chatIcon from '../../assets/ticketsTypes/chat.png';
import ContactSelect from "../../components/ContactSelect";
import LabelSelect from "../../components/LabelSelect";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import NoDataImg from '../../assets/noData.svg';
import QueueSelect from "../../components/QueueSelect";
import RatingsSelect from "../../components/RatingsSelect";
import SubqueueSelect from "../../components/SubqueueSelect";
import TicketTypeSelect from "../../components/TicketTypeSelect";
import Title from "../../components/Title";
import UserSelect from "../../components/UserSelect";
import useTicketsDashboardTable from "../../hooks/useTicketsDashboardTable";
import ViewTicketModal from "../../components/ViewTicketModal";
import whatsappIcon from '../../assets/ticketsTypes/whatsapp.png';
import WhatsappSelect from "../../components/WhatsappSelect";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
	viewTicket: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100px",
		maxWidth: "100px",
		minWidth: "100px",
		alignItems: "center",
		gap: "2px",
	},

	viewPlatform: { width: "100%", display: "flex", alignItems: "center", justifyContent: "center", },
	
	viewButton: { cursor: "pointer", borderRadius: "50%", },
  
	mainContainerScroll: { overflowY: "scroll", ...theme.scrollbarStyles, paddingBottom: "20px", },

  container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), },

  flexContainer: {
		display: "flex",
		justifyContent: "right",
		alignItems: "baseline",
		gap: "15px",
		paddingRight: "10px",
	},

  customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "hidden",
		flexDirection: "column",
		height: 120,
		transition: 'transform 0.3s',
		position: "relative",
		'&:hover': { filter: "brightness(0.85)", transform: 'translateY(-5px)', },
	},

	sectionContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
		[theme.breakpoints.down('sm')]: { justifyContent: "center", gap: "10px", },
	},

	flexCenteredGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

	filterField: { width: "250px", },

	messagesKeywordsGrid: { display: "flex", flexDirection: "row", gap: "2px", },

	cardItem: { width: "300px", },

	ticketsTable: {
		// scroll
		"& .MuiDataGrid-virtualScroller": { ...theme.scrollbarStyles, },

		// header
		"& .MuiDataGrid-columnHeaders": {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			fontSize: 16,
		},

		// rows
		"& .MuiDataGrid-cell": { color: theme.palette.text.primary, },

		// pair rows
		"& .MuiDataGrid-virtualScrollerRenderZone": {
			"& .MuiDataGrid-row": {
				"&:nth-child(2n)": { backgroundColor: theme.palette.background.paper, }
			}
		},

		// buttons
		"& .MuiButtonBase-root": { color: theme.palette.text.primary, },

		// checkboxes - not checked
		"& [data-testId='CheckBoxOutlineBlankIcon']": { color: theme.palette.text.primary, },

		// checkboxes - checked
		"& [data-testId='CheckBoxIcon']": { color: theme.palette.primary.main, },

		// checkbox - header (unselect all)
		"& [data-testId='IndeterminateCheckBoxIcon']": { color: theme.palette.primary.main, },

		// footer - selected rows
		"& .MuiDataGrid-footerContainer": { color: theme.palette.text.primary, },

		// footer - pagination
		"& .MuiTablePagination-root": { color: theme.palette.text.primary, },
	},

	emptyBoxContainer: {
    display: "flex",
		width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px", },

  emptyBoxSpan: { fontSize: "20px", },

	dashboardContainer: {
		width: "100%",
		height: "400px",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},
	
	messageKeywordsButton: {
		color: theme.palette.text.primary,
		"&:hover": { color: theme.palette.primary.main, },
	},

	icon: { color: theme.palette.text.primary },

	floatingButton: { transition: 'transform 0.3s', '&:hover': { transform: 'translateY(-5px)', }, },

	ticketType: { width: "20px", },

	actionButton: { "&:hover": { color: theme.palette.primary.main, }, },

	inputField: {
		'& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: theme.palette.text.primary, },
			'&:hover fieldset': { borderColor: theme.palette.text.primary, },
      '&.Mui-focused fieldset': { borderColor: theme.palette.text.primary, },
		},
	}
}));

const TicketsDashboard = () => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const { user } = useContext(AuthContext);

	const [viewTicketModalOpen, setViewTicketModalOpen] = useState(false);
	const [ticketId, setTicketId] = useState();
	const [initialDate, setInitialDate] = useState();
	const [finalDate, setFinalDate] = useState();

	const [selectedLabelIds, setSelectedLabelIds] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
	const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedSubqueueIds, setSelectedSubqueueIds] = useState([]);
	const [selectedTicketTypes, setSelectedTicketTypes] = useState([]);
	const [selectedWhatsappIds, setSelectedWhatsappIds] = useState([]);
	const [selectedRatings, setSelectedRatings] = useState([]);

	const [resaleSerialNumber, setResaleSerialNumber] = useState("");
	const [serialNumber, setSerialNumber] = useState("");
	const [clientCode, setClientCode] = useState("");

	const [messageKeywords, setMessageKeywords] = useState([]);
	const [messageKeywordsTicketsIds, setMessageKeywordsTicketsIds] = useState([]);

	const [isTicketsDashboardBeingGenerated, setIsTicketsDashboardBeingGenerated] = useState(false);

	const { allLabels } = useGlobalRegisters();


	let timesTicket = 0;

	let closedTickets = 0;
	let structuredClosedTickets = 0;
	let viewClosedTickets = 0;
	let filteredClosedTickets = 0;



  // 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const today = new Date();		
		let day = today.getDate();
		let month = today.getMonth() + 1;
		let year = today.getFullYear();

		if (month < 10) { month = '0' + month.toString(); }
		if (day < 10) { day = '0' + day.toString(); }

	 	setInitialDate(`${year}-${month}-${day}`);
		setFinalDate(`${year}-${month}-${day}`);		
  }, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleOpenViewTicketModal = (ticketId) => {
    setViewTicketModalOpen(true);
		setTicketId(ticketId);
  };

	const handleLabelFilterChange = (values) => { setSelectedLabelIds(values); }
  const handleCloseViewTicketModal = () => { setViewTicketModalOpen(false); };

	const GetFasterTicket = () => {
		const fasterTicket = timesTicket === 0 ? 0 : timesTicket.reduce((min, current) => current < min ? current : min, Infinity);
		let message;

		// Caso não há nenhum atendimento com a propriedade `withUserAt` diferente
		// de NULL OU a média de espera é menor do que 2, "1 minuto" é retornado.
		if (isNaN(parseInt(fasterTicket)) || fasterTicket < 2) {
			message = `1 ${i18n.t("ticketsDashboard.minutes")}`;
		}

		// Caso contrário, é retornado a média de espera.
		else {

			// Convertendo tempo em minutos
			if (fasterTicket < 60) {
				message = fasterTicket + ` ${i18n.t("ticketsDashboard.minutes")}`;
			}

			// Convertendo tempo em horas
			else {
				const fasterTicketHours = ~~(fasterTicket / 60);

				if (fasterTicketHours > 1) {
					message = fasterTicketHours + ` ${i18n.t("ticketsDashboard.hours")}`;
				}
				else {
					message = fasterTicketHours + ` ${i18n.t("ticketsDashboard.hour")}`;
				}
			}
		}

		return message;
	}

	const GetLongerTicket = () => {
		const longerTicket = timesTicket === 0 ? 0 : timesTicket.reduce((max, current) => current > max ? current : max, -Infinity);
		let message;

		// Caso não há nenhum atendimento com a propriedade `withUserAt` diferente
		// de NULL OU a média de espera é menor do que 2, "1 minuto" é retornado.
		if (isNaN(parseInt(longerTicket)) || longerTicket < 2) {
			message = `1 ${i18n.t("ticketsDashboard.minutes")}`;
		}

		// Caso contrário, é retornado a média de espera.
		else {

			// Convertendo tempo em minutos
			if (longerTicket < 60) {
				message = longerTicket + ` ${i18n.t("ticketsDashboard.minutes")}`;
			}

			// Convertendo tempo em horas
			else {
				const longerTicketHours = ~~(longerTicket / 60);

				if (longerTicketHours > 1) {
					message = longerTicketHours + ` ${i18n.t("ticketsDashboard.hours")}`;
				}
				else {
					message = longerTicketHours + ` ${i18n.t("ticketsDashboard.hour")}`;
				}
			}
		}

		return message;
	}

	const GetClosedTicketsTable = (initialDate, finalDate, considerInternalChats) => {
    const structuredInitialDate = initialDate ? `${initialDate}T00:00:00` : "2022-12-29T00:00:00";
    const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : "2022-12-29T23:59:59";

    const queryResult = useTicketsDashboardTable({
      initialDate: structuredInitialDate, 
      finalDate: structuredFinalDate,
			considerInternalChats
    });

    return queryResult;
  }

	const GetMessageKeywordsTicketIds = async (initialDate, finalDate, considerInternalChats) => {
		const structuredInitialDate = initialDate ? `${initialDate}T00:00:01` : `2022-12-29T00:00:01`;
		const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : `2022-12-29T23:59:59`;

		const { data } = await api.get("/listMessageKeywordsTicketsId", {
			params: { 
				initialDate: structuredInitialDate,
				finalDate: structuredFinalDate,
				messageKeywords: messageKeywords,
				considerInternalChats
			},
		});

		const queryResult = data.map(d => { return d.id; });

		return queryResult;
	}

  const handleFilterDate = (newDate, flagInitialDate) => {
		const dashboardInitialDate = document.getElementById("dashboardInitialDate");
		const dashboardFinalDate = document.getElementById("dashboardFinalDate");
		
		if (dashboardInitialDate.value === "" || dashboardFinalDate.value === "") { 
			dashboardInitialDate.value = initialDate;
			dashboardFinalDate.value = finalDate;
		}
		else if (dashboardInitialDate.value > dashboardFinalDate.value) { toast.info(i18n.t("backendErrors.DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE")); }
		else if (flagInitialDate) { 
			setInitialDate(newDate.target.value); 
			setSelectedUserIds([]);
			setSelectedContactIds([]);
			setSelectedQueueIds([]);
			setSelectedCategoryIds([]);
			setSelectedSubqueueIds([]);
			setSelectedTicketTypes([]);
			setSelectedWhatsappIds([]);
			setSelectedRatings([]);

			setResaleSerialNumber("");
			setSerialNumber("");
			setClientCode("");

			setMessageKeywords([]);
			setMessageKeywordsTicketsIds([]);
		}
		else { 
			setFinalDate(newDate.target.value); 
			setSelectedUserIds([]);
			setSelectedContactIds([]);
			setSelectedQueueIds([]);
			setSelectedCategoryIds([]);
			setSelectedSubqueueIds([]);
			setSelectedTicketTypes([]);
			setSelectedWhatsappIds([]);
			setSelectedRatings([]);

			setResaleSerialNumber("");
			setSerialNumber("");
			setClientCode("");

			setMessageKeywords([]);
			setMessageKeywordsTicketsIds([]);
		}
	}

  const handleChangeInitialDate = (newDate) => { handleFilterDate(newDate, true); }

	const handleChangeFinalDate = (newDate) => { handleFilterDate(newDate, false); }

	const handleBlurResaleSerialNumber = (event) => {
		setResaleSerialNumber(event.target.value);
	}

	const handleBlurSerialNumber = (event) => {
		setSerialNumber(event.target.value);
	}

	const handleBlurClientCode = (event) => {
		setClientCode(event.target.value);
	}

	const handleBlurMessageKeywords = async (event) => {
		setMessageKeywords(
			event.target.value.trim().replaceAll(",", "").split(" ")[0] === ""
			? []
			: event.target.value.trim().replaceAll(",", "").split(" ")
		);
	};

	const handleSearchMessageKeywords = async (considerInternalChats) => {
		if (messageKeywords.length > 0) {
			setMessageKeywordsTicketsIds(await GetMessageKeywordsTicketIds(initialDate, finalDate, considerInternalChats));
		}
		else {
			setMessageKeywordsTicketsIds([]);
		}
	};



	// 	**********************
	// 	** Table Data Query **
	// 	**********************
	closedTickets = GetClosedTicketsTable(initialDate, finalDate, false);

	structuredClosedTickets = closedTickets === 0 ? 0 : closedTickets.map((ticket) => {
		const { id, rate, protocol, createdAt, isClosedAt, withUserAt, user, type } = ticket;

		const whatsappId = ticket.whatsapp?.id;
		const whatsappName = ticket.whatsapp?.name;

		const contactName = ticket.contact?.name || ticket.noteName;
		const contactNumber = ticket.contact?.number;
		const contactLabels = ticket.contact?.labels;
		const contactId = ticket.contact?.id;

		const resaleSerialNumber = ticket.contact?.resaleSerialNumber;
		const serialNumber = ticket.contact?.serialNumber;
		const clientCode = ticket.contact?.clientCode;

		const queueName = ticket.queue ? ticket.queue.name : null;
		const queueId = ticket.queue ? ticket.queue.id : null;

		const subqueueName = ticket.subqueue ? ticket.subqueue.name : null;
		const subqueueId = ticket.subqueue ? ticket.subqueue.id : null;

		const categoryName = ticket.subqueue && ticket.subqueue.category ? ticket.subqueue.category.name : null;
		const categoryId = ticket.subqueue && ticket.subqueue.category ? ticket.subqueue.category.id : null;
		
		const userName = user ? user.name : null;
		const userId = user ? user.id : null;

		const createdAtDate = createdAt ? new Date(createdAt).toLocaleDateString(localStorage.getItem) : null;
		const isClosedAtDate = isClosedAt ? new Date(isClosedAt).toLocaleDateString(localStorage.getItem) : null;

		const isClosedAtCalc = new Date(isClosedAt);
  	const withUserAtCalc = new Date(withUserAt);
  	const diffInMinutes = Math.floor((isClosedAtCalc - withUserAtCalc) / (1000 * 60));
	
		return {
			id,
			rate,
			whatsappId,
			whatsappName,
			protocol,
			queueName,
			queueId,
			subqueueName,
			subqueueId,
			categoryName,
			categoryId,
			createdAt: createdAtDate,
			isClosedAt: isClosedAtDate,
			originalCreatedAt: createdAt,
			originalIsClosedAt: isClosedAt,
			userName,
			userId,
			time: diffInMinutes,
			type,
			resaleSerialNumber,
			serialNumber,
			clientCode,
			contactName,
			contactNumber,
			contactLabels,
			contactId
		};
	});



	// ***---- Filtered DataTable ----***
	
	// se as informações já foram estruturadas e o usuário é administrador,
	// este conseguirá ver todas as informações
	if (structuredClosedTickets !== 0 && user.profile === "admin") {
		viewClosedTickets = structuredClosedTickets;
		filteredClosedTickets = viewClosedTickets;
	}

	// se as informações já foram estruturadas e o usuário não é administrador,
	// este conseguirá ver somente as informações relacionadas a ele
	else if (structuredClosedTickets !== 0 && user.profile !== "admin") {
		viewClosedTickets = structuredClosedTickets.filter(ticket => ticket.userName === user.name);
		filteredClosedTickets = viewClosedTickets;
	}
	
	// Filtros aplicados pelo usuário
	if (messageKeywordsTicketsIds.length > 0 || selectedUserIds.length > 0 || selectedContactIds.length > 0
		|| selectedQueueIds.length > 0 || selectedCategoryIds.length > 0
		|| selectedSubqueueIds.length > 0 || selectedLabelIds.length > 0 || selectedTicketTypes.length > 0
		|| selectedRatings.length > 0 || selectedWhatsappIds.length > 0
		|| resaleSerialNumber !== "" || serialNumber !== "" || clientCode !== "")
	{

		filteredClosedTickets = viewClosedTickets;

		// ***---- Filtro de Palavra-Chave ----***
		if (messageKeywordsTicketsIds.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return messageKeywordsTicketsIds.includes(ticket.id);
			});
		}
		
		// ***---- Filtro de Usuário ----***
		if (selectedUserIds.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return selectedUserIds.includes(ticket.userId);
			});
		}

		// ***---- Filtro de Contato ----***
		if (selectedContactIds.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return selectedContactIds.includes(ticket.contactId);
			});
		}

		// ***---- Filtro de Whatsapps ----***
		if (selectedWhatsappIds.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return selectedWhatsappIds.includes(ticket.whatsappId);
			});
		}

		// ***---- Filtro de Filas ----***
		if (selectedQueueIds.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return selectedQueueIds.includes(ticket.queueId);
			});
		}

		// ***---- Filtro de Categorias ----***
		if (selectedCategoryIds.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return selectedCategoryIds.includes(ticket.categoryId);
			});
		}

		// ***---- Filtro de Subfilas ----***
		if (selectedSubqueueIds.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return selectedSubqueueIds.includes(ticket.subqueueId);
			});
		}

		// ***---- Filtro de Labels ----***
		if (selectedLabelIds.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return ticket.contactLabels.some(label => selectedLabelIds.includes(label.id));
			});
		}

		// ***---- Filtro de Tipos de Tickets ----***
		if (selectedTicketTypes.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return selectedTicketTypes.includes(ticket.type);
			});
		}

		// ***---- Filtro de Avaliações ----***
		if (selectedRatings.length > 0) {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => {
				return selectedRatings.includes(ticket.rate);
			});
		}

		// ***---- Filtro de Número da Revenda ----***
		if (resaleSerialNumber !== "") {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => ticket.resaleSerialNumber === resaleSerialNumber);
		}

		// ***---- Filtro de Número de Série ----***
		if (serialNumber !== "") {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => ticket.serialNumber === serialNumber);
		}

		// ***---- Filtro de Código de Cliente ----***
		if (clientCode !== "") {
			filteredClosedTickets = filteredClosedTickets.filter(ticket => ticket.clientCode === clientCode);
		}
	}

	// ***---- Times ----***
	timesTicket = filteredClosedTickets === 0 ? 0 : filteredClosedTickets.map(ticket => ticket.time);



	// ***---- Filter Options Query ----***

	// - Users
	const uniqueUsers = [];

	if (viewClosedTickets !== 0 && viewClosedTickets.length !== 0) {
		viewClosedTickets.forEach(ticket => {
			const existingUser = uniqueUsers.length === 0
				? false
				: uniqueUsers.find(user => user.id === ticket.userId);
			
			if (!existingUser && ticket.userId) uniqueUsers.push({ id: ticket.userId, name: ticket.userName });
		});
	}

	// - Contacts
	const uniqueContacts = [];

  if (viewClosedTickets !== 0 && viewClosedTickets.length !== 0) {
    viewClosedTickets.forEach(ticket => {
      const existingContact = uniqueContacts.length === 0
				? false
				: uniqueContacts.find(contact => contact.id === ticket.contactId);

      if (!existingContact && ticket.contactId) uniqueContacts.push({ id: ticket.contactId, name: ticket.contactName });
    });
  }

	// - Queues
	const uniqueQueues = [];

	if (viewClosedTickets !== 0 && viewClosedTickets.length !== 0) {
    viewClosedTickets.forEach(ticket => {
      const existingQueue = uniqueQueues.length === 0
				? false
				: uniqueQueues.find(queue => queue.id === ticket.queueId);

      if (!existingQueue && ticket.queueId) uniqueQueues.push({ id: ticket.queueId, name: ticket.queueName });
    });
  }

	// - Categories
	const uniqueCategories = [];

	if (viewClosedTickets !== 0 && viewClosedTickets.length !== 0) {
    viewClosedTickets.forEach(ticket => {
      const existingCategory = uniqueCategories.length === 0
				? false
				: uniqueCategories.find(category => category.id === ticket.categoryId);

      if (!existingCategory && ticket.categoryId) uniqueCategories.push({ id: ticket.categoryId, name: ticket.categoryName });
    });
  }

	// - Subqueues
	const uniqueSubqueues = [];

	if (viewClosedTickets !== 0 && viewClosedTickets.length !== 0) {
    viewClosedTickets.forEach(ticket => {
      const existingSubqueue = uniqueSubqueues.length === 0
				? false
				: uniqueSubqueues.find(subqueue => subqueue.id === ticket.subqueueId);

      if (!existingSubqueue && ticket.subqueueId) uniqueSubqueues.push({ id: ticket.subqueueId, name: ticket.subqueueName });
    });
  }

	// - Whatsapps
	const uniqueWhatsapps = [];

	if (viewClosedTickets !== 0 && viewClosedTickets.length !== 0) {
    viewClosedTickets.forEach(ticket => {
      const existingWhatsapp = uniqueWhatsapps.length === 0
				? false
				: uniqueWhatsapps.find(whatsapp => whatsapp.id === ticket.whatsappId);

      if (!existingWhatsapp && ticket.whatsappId) uniqueWhatsapps.push({ id: ticket.whatsappId, name: ticket.whatsappName });
    });
  }

	// - Ticket Types
	const ticketTypes = viewClosedTickets === 0 ? [] : [0, 1, 2];

	// ***---- Table Structure ----***
	let columns;

	// FX Sistemas Tenant - Admin User
	if (user.tenantId.toString() === "1" && user.profile === "admin") {
		columns = [
			{ 
				field: 'id', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.id"), width: 100, align: "center",
				renderCell: (params) => (
					<div className={classes.viewTicket}>
						<MenuItem
							className={`${classes.actionButton} ${classes.viewButton}`}
							onClick={() => {handleOpenViewTicketModal(params.value)}}
						>
							<VisibilityOutlined />
						</MenuItem>
						{params.value}
					</div>
				),
			},
	
			{
				field: 'type', headerName: i18n.t("ticketsDashboard.table.header.platform"), width: 130,
				type: 'number',
				renderCell: (params) => (
					<div className={classes.viewPlatform}>
						{params.value === 0 && (
							<img
								className={classes.ticketType}
								src={whatsappIcon}
								alt="Ticket Logo" 
							/>
						)}
	
						{params.value === 1 && (
							<img
								className={classes.ticketType}
								src={chatIcon}
								alt="Ticket Logo" 
							/>
						)}

						{params.value === 2 && (
							<img
								className={classes.ticketType}
								src={botChatIcon}
								alt="Ticket Logo"
							/>
						)}
					</div>
				),
			},

			{
				field: "rate", type: "number", headerName: i18n.t("ticketsDashboard.table.header.rate"), width: 100,
				valueGetter: (params) => { return params.value ? params.value : "-" },
			},
	
			{ field: 'userName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.user"), width: 130, },

			{
				field: 'contactName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.contactName"), width: 150,
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},

			{ 
				field: 'contactNumber', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.contactNumber"), width: 150,
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},
	
			{ 
				field: 'resaleSerialNumber', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.resaleSerialNumber"), width: 200, align:"center",
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},
			{ 
				field: 'serialNumber', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.serialNumber"), width: 150, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},
			
			{ 
				field: 'queueName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.queue"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'categoryName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.category"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'subqueueName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.subqueue"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'protocol', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.protocol"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			
			{ 
				field: 'time', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.time"), width: 130, align: "center",
				renderCell: (params) => (
					<div className={classes.viewPlatform}>
						{params.value <= 1 && (
							`${params.value} ${i18n.t("ticketsDashboard.table.rows.minute")}`
						)}
	
						{params.value > 1 && params.value < 60 && (
							`${params.value} ${i18n.t("ticketsDashboard.table.rows.minutes")}`
						)}
	
						{params.value > 60 && ~~(params.value / 60) === 1 && (
							`${~~(params.value / 60)} ${i18n.t("ticketsDashboard.table.rows.hour")}`
						)}
	
						{params.value > 60 && ~~(params.value / 60) > 1 && (
							`${~~(params.value / 60)} ${i18n.t("ticketsDashboard.table.rows.hours")}`
						)}
					</div>
				),
			},
	
			{ field: 'isClosedAt', type: 'date', headerName: i18n.t("ticketsDashboard.table.header.endedAt"), width: 130, align: "center" },
		];
	}

	// FX Sistemas Tenant - Non-Admin User
	else if (user.tenantId.toString() === "1" && user.profile === "user") {
		columns = [
			{ 
				field: 'id', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.id"), width: 100, align: "center",
				renderCell: (params) => (
					<div className={classes.viewTicket}>
						<MenuItem
							className={`${classes.actionButton} ${classes.viewButton}`}
							onClick={() => {handleOpenViewTicketModal(params.value)}}
						>
							<VisibilityOutlined />
						</MenuItem>
						{params.value}
					</div>
				),
			},

			{ field: 'whatsappName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.connection"), width: 130, },
	
			{
				field: 'type', headerName: i18n.t("ticketsDashboard.table.header.platform"), width: 130,
				type: 'number',
				renderCell: (params) => (
					<div className={classes.viewPlatform}>
						{params.value === 0 && (
							<img
								className={classes.ticketType}
								src={whatsappIcon}
								alt="Ticket Logo" 
							/>
						)}
	
						{params.value === 1 && (
							<img
								className={classes.ticketType}
								src={chatIcon}
								alt="Ticket Logo" 
							/>
						)}

						{params.value === 2 && (
							<img
								className={classes.ticketType}
								src={botChatIcon}
								alt="Ticket Logo"
							/>
						)}
					</div>
				),
			},
	
			{ field: 'userName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.user"), width: 130, },

			{
				field: 'contactName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.contactName"), width: 150,
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},

			{ 
				field: 'contactNumber', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.contactNumber"), width: 150,
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},
	
			{ 
				field: 'resaleSerialNumber', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.resaleSerialNumber"), width: 200, align:"center",
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},
			{ 
				field: 'serialNumber', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.serialNumber"), width: 150, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},
			
			{ 
				field: 'queueName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.queue"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'categoryName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.category"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'subqueueName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.subqueue"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'protocol', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.protocol"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			
			{ 
				field: 'time', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.time"), width: 130, align: "center",
				renderCell: (params) => (
					<div className={classes.viewPlatform}>
						{params.value <= 1 && (
							`${params.value} ${i18n.t("ticketsDashboard.table.rows.minute")}`
						)}
	
						{params.value > 1 && params.value < 60 && (
							`${params.value} ${i18n.t("ticketsDashboard.table.rows.minutes")}`
						)}
	
						{params.value > 60 && ~~(params.value / 60) === 1 && (
							`${~~(params.value / 60)} ${i18n.t("ticketsDashboard.table.rows.hour")}`
						)}
	
						{params.value > 60 && ~~(params.value / 60) > 1 && (
							`${~~(params.value / 60)} ${i18n.t("ticketsDashboard.table.rows.hours")}`
						)}
					</div>
				),
			},
	
			{ field: 'isClosedAt', type: 'date', headerName: i18n.t("ticketsDashboard.table.header.endedAt"), width: 130, align: "center" },
		];
	}

	// Other Tenants - Admin User
	else if (user.profile === "admin") {
		columns = [
			{ 
				field: 'id', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.id"), width: 100, align: "center",
				renderCell: (params) => (
					<div className={classes.viewTicket}>
						<MenuItem
							className={`${classes.actionButton} ${classes.viewButton}`}
							onClick={() => {handleOpenViewTicketModal(params.value)}}
						>
							<VisibilityOutlined />
						</MenuItem>
						{params.value}
					</div>
				),
			},
	
			{
				field: 'type', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.platform"), width: 130,
				renderCell: (params) => (
					<div className={classes.viewPlatform}>
						{params.value === 0 && (
							<img
								className={classes.ticketType}
								src={whatsappIcon}
								alt="Ticket Logo" 
							/>
						)}
	
						{params.value === 1 && (
							<img
								className={classes.ticketType}
								src={chatIcon}
								alt="Ticket Logo" 
							/>
						)}

						{params.value === 2 && (
							<img 
								className={classes.ticketType}
								src={botChatIcon}
								alt="Ticket Logo"
							/>
						)}
					</div>
				),
			},

			{
				field: "rate", type: "number", headerName: i18n.t("ticketsDashboard.table.header.rate"), width: 100,
				valueGetter: (params) => { return params.value ? params.value : "-" },
			},
	
			{ field: 'userName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.user"), width: 130, },
	
			{
				field: 'contactName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.contactName"), width: 150,
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},

			{ 
				field: 'contactNumber', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.contactNumber"), width: 150,
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},
	
			{ 
				field: 'clientCode', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.clientCode"), width: 150, align: "center",
				valueGettar: (params) => {return params.value ? params.value : "-"},
			},
			
			{ 
				field: 'queueName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.queue"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'categoryName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.category"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'subqueueName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.subqueue"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'protocol', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.protocol"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			
			{ 
				field: 'time', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.time"), width: 130, align: "center",
				renderCell: (params) => (
					<div className={classes.viewPlatform}>
						{params.value <= 1 && (
							`${params.value} ${i18n.t("ticketsDashboard.table.rows.minute")}`
						)}
	
						{params.value > 1 && params.value < 60 && (
							`${params.value} ${i18n.t("ticketsDashboard.table.rows.minutes")}`
						)}
	
						{params.value > 60 && ~~(params.value / 60) === 1 && (
							`${~~(params.value / 60)} ${i18n.t("ticketsDashboard.table.rows.hour")}`
						)}
	
						{params.value > 60 && ~~(params.value / 60) > 1 && (
							`${~~(params.value / 60)} ${i18n.t("ticketsDashboard.table.rows.hours")}`
						)}
					</div>
				),
			},
	
			{ field: 'isClosedAt', type: 'date', headerName: i18n.t("ticketsDashboard.table.header.endedAt"), width: 130, align: "center" },
		];
	}

	// Other Tenants - Non-Admin User
	else {
		columns = [
			{ 
				field: 'id', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.id"), width: 100, align: "center",
				renderCell: (params) => (
					<div className={classes.viewTicket}>
						<MenuItem
							className={`${classes.actionButton} ${classes.viewButton}`}
							onClick={() => {handleOpenViewTicketModal(params.value)}}
						>
							<VisibilityOutlined />
						</MenuItem>
						{params.value}
					</div>
				),
			},

			{ field: 'whatsappName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.connection"), width: 130, },
	
			{
				field: 'type', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.platform"), width: 130,
				renderCell: (params) => (
					<div className={classes.viewPlatform}>
						{params.value === 0 && (
							<img
								className={classes.ticketType}
								src={whatsappIcon}
								alt="Ticket Logo" 
							/>
						)}
	
						{params.value === 1 && (
							<img
								className={classes.ticketType}
								src={chatIcon}
								alt="Ticket Logo" 
							/>
						)}

						{params.value === 2 && (
							<img 
								className={classes.ticketType}
								src={botChatIcon}
								alt="Ticket Logo"
							/>
						)}
					</div>
				),
			},
	
			{ field: 'userName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.user"), width: 130, },

			{
				field: 'contactName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.contactName"), width: 150,
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},

			{ 
				field: 'contactNumber', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.contactNumber"), width: 150,
				valueGetter: (params) => {return params.value ? params.value : "-"},
			},
	
			{ 
				field: 'clientCode', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.clientCode"), width: 150, align: "center",
				valueGettar: (params) => {return params.value ? params.value : "-"},
			},
			
			{ 
				field: 'queueName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.queue"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'categoryName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.category"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'subqueueName', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.subqueue"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			{ 
				field: 'protocol', type: 'string', headerName: i18n.t("ticketsDashboard.table.header.protocol"), width: 130, align: "center",
				valueGetter: (params) => {return params.value ? params.value : "-" },
			},
			
			{ 
				field: 'time', type: 'number', headerName: i18n.t("ticketsDashboard.table.header.time"), width: 130, align: "center",
				renderCell: (params) => (
					<div className={classes.viewPlatform}>
						{params.value <= 1 && (
							`${params.value} ${i18n.t("ticketsDashboard.table.rows.minute")}`
						)}
	
						{params.value > 1 && params.value < 60 && (
							`${params.value} ${i18n.t("ticketsDashboard.table.rows.minutes")}`
						)}
	
						{params.value > 60 && ~~(params.value / 60) === 1 && (
							`${~~(params.value / 60)} ${i18n.t("ticketsDashboard.table.rows.hour")}`
						)}
	
						{params.value > 60 && ~~(params.value / 60) > 1 && (
							`${~~(params.value / 60)} ${i18n.t("ticketsDashboard.table.rows.hours")}`
						)}
					</div>
				),
			},
	
			{ field: 'isClosedAt', type: 'date', headerName: i18n.t("ticketsDashboard.table.header.endedAt"), width: 130, align: "center" },
		];
	}



	// 	*************
	// 	** Reports **
	// 	*************
	const generateTicketsReport = (ticketsData, statisticsTicketsData) => {
		const { document, headerInitialPeriod, headerFinalPeriod, footerCreationDateTimeText } = documentGenerator({
			documentProperties: {
				orientation: "p"
				, measure: "mm"
				, dimensions: "a4"
				, autoPrint: false
				, variant: "non-conform"
			}
			, period: { initialDate, finalDate }
		});

		const options = {
			...reportOptions

			, margin: { top: 30, bottom: 20 }

			, willDrawPage: (data) => {
				header({
					document
					, titleText: i18n.t("ticketsReport.document.table.info.title")
					, headerText: i18n.t("ticketsReport.document.table.info.period")
					, headerInitialPeriod
					, headerFinalPeriod
				});
			}
			, didDrawPage: (data) => footer({ document, footerCreationDateTimeText, data })
			, willDrawCell: (data) => {
				alignHeader({ data });

				if (data.table.id === "ticketsData") {
					formatDate({ data, dataKeys: [0, 1] });
					encodeEmptyStringDataToMissing({ data, dataKeys: [3, 4, 5, 6] });
					alignFooter({ data });
				}
			}
		};

		// ***---- Tickets Table ----***
		const columnsTickets = [
			i18n.t("ticketsReport.document.table.header.createdAt")
			, i18n.t("ticketsReport.document.table.header.endedAt")
			, i18n.t("ticketsReport.document.table.header.user")
			, i18n.t("ticketsReport.document.table.header.contactName")
			, i18n.t("ticketsReport.document.table.header.contactNumber")
			, i18n.t("ticketsReport.document.table.header.queue")
			, i18n.t("ticketsReport.document.table.header.protocol")
			, i18n.t("ticketsReport.document.table.header.time")
		];

		tableGenerator({
			document
			, title: {
				text: i18n.t("ticketsReport.document.table.info.chats")
				, positionX: 15
				, positionY: 35
				, fontSize: 12
			}
			, table: {
				tableId: "ticketsData"
				, columns: [columnsTickets]
				, rows: ticketsData
				, columnStyles: { 0: { halign: "center", valign: "middle" }, 1: { halign: "center", valign: "middle" }, 2: { halign: "center", valign: "middle" }, 3: { halign: "center", valign: "middle" }, 4: { halign: "center", valign: "middle" }, 5: { halign: "center", valign: "middle" }, 6: { halign: "center", valign: "middle" }, 7: { halign: "center", valign: "middle" } }
				, startY: 40
				, foot: [
					[
						{ content: "", colSpan: 6, }
						, { content: `${i18n.t("report.footer.total")} ${statisticsTicketsData[0][7]}`, colSpan: 2, }
					],
				]
				, options
			}
		});

		// ***---- Stats Table (Time) ----***
		const columnsStatistics = [
			i18n.t("report.statistics.variables.mean")
			, i18n.t("report.statistics.variables.standardDeviation")
			, i18n.t("report.statistics.variables.range")
			, i18n.t("report.statistics.variables.firstQuartile")
			, i18n.t("report.statistics.variables.secondQuartile")
			, i18n.t("report.statistics.variables.thirdQuartile")
			, i18n.t("report.statistics.variables.fourthQuartile")
		];

		tableGenerator({
			document
			, title: {
				text: `${i18n.t("ticketsReport.document.table.info.statistics")} - ${i18n.t("ticketsReport.document.table.header.time")}`
				, positionX: 15
				, positionY: document.lastAutoTable.finalY + 10
				, fontSize: 12
			}
			, table: {
				tableId: "statisticsTicketsData"
				, columns: [columnsStatistics]
				, rows: statisticsTicketsData
				, columnStyles: { 0: { halign: "center", valign: "middle" }, 1: { halign: "center", valign: "middle" }, 2: { halign: "center", valign: "middle" }, 3: { halign: "center", valign: "middle" }, 4: { halign: "center", valign: "middle" }, 5: { halign: "center", valign: "middle" }, 6: { halign: "center", valign: "middle" } }
				, startY: document.lastAutoTable.finalY + 15
				, foot: []
				, options
			}
		});

		writeStatisticsCaption({ document, positionX: 15, positionY: document.lastAutoTable.finalY + 10, fontSize: 10 });

		// ***---- Exporting Report ----***
		exportToNewWindow({ document });
	};

	const handleGenerateTicketsReport = () => {
		// ***---- Validations ----***
		if (isTicketsDashboardBeingGenerated) {
			toast.info(i18n.t("report.validations.tooManyRequests"));
			return;
		}

		if (filteredClosedTickets.length === 0) {
			toast.info(i18n.t("report.validations.emptyReport"));
			return;
		}

		// ***---- Generating Report ----***
		try {
			setIsTicketsDashboardBeingGenerated(true);

			const ticketsData = filteredClosedTickets.map(ticket => [
				ticket.originalCreatedAt.substring(0, 10)
				, ticket.originalIsClosedAt.substring(0, 10)
				, ticket.userName
				, ticket.contactName
				, ticket.contactNumber
				, ticket.queueName
				, ticket.protocol
				, ticket.time
			]).sort((ticketA, ticketB) => {
				const userNameComparison = ticketA[2].localeCompare(ticketB[2]);
				if (userNameComparison !== 0) return userNameComparison
				return ticketB[7] - ticketA[7]
			});

			const statisticsTicketsData = calculateSimpleDescribe({
				data: ticketsData.map(ticket => parseFloat(ticket[7]))
				, returnResultAsObject: false
			});

			generateTicketsReport(ticketsData, statisticsTicketsData);
		}
		catch (exception) { toast.error(exception); }
		finally { setIsTicketsDashboardBeingGenerated(false); }
	};



  // 	************
	// 	** Return **
	// 	************
  return (
		<>
		<ViewTicketModal open={viewTicketModalOpen} onClose={handleCloseViewTicketModal} ticketId={ticketId} />

    <MainContainer>
      {/* 
					***********
					** Title **
					***********
			*/}
      <MainHeader>
        <Title>{i18n.t("ticketsDashboard.title")}</Title>
      </MainHeader>



      {/* 
					***************
					** Container **
					***************
			*/}
      <div className={classes.mainContainerScroll}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* 
								*****************
								** Date Inputs **
								***************** 
						*/}
            <Grid item xs={12}>
              <div className={classes.flexContainer}>
                <TextField
									variant="outlined"
									margin="normal"
									type="date"
									id="dashboardInitialDate"
									name="dashboardInitialDate"
									label={i18n.t("dashboard.fields.initialDate")}
									value={initialDate}
									onChange={handleChangeInitialDate}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									type="date"
									id="dashboardFinalDate"
									name="dashboardFinalDate"
									label={i18n.t("dashboard.fields.finalDate")}
									value={finalDate}
									onChange={handleChangeFinalDate}
								/>
              </div>
            </Grid>

						{/* when are datas over the selected period, the table is shown */}
						{(viewClosedTickets !== 0 && viewClosedTickets.length > 0) && (
							<>
							{/*
								*************
								** Filters **
								*************
							*/}
							<Grid item xs={12} className={classes.sectionContainer}>

								{/* Users List */}
								<Can
									role={user.profile}
									perform="ticket-options:deleteTicket"
									yes={() => (
										<Grid className={classes.filterField}>
											<UserSelect 
												selectedUserIds={selectedUserIds}
												onChange={values => setSelectedUserIds(values)}
												usersOptions={uniqueUsers}
												styleSelectInputField={true}
											/>
										</Grid>	
									)}
								/>

								{/* Contacts List */}
								<Grid className={classes.filterField}>
									<ContactSelect
										selectedContactIds={selectedContactIds}
										onChange={values => setSelectedContactIds(values)}
										contactsOptions={uniqueContacts}
										styleSelectInputField={true}
										selectedItemsDisplayLimit={5}
									/>
								</Grid>
								
								{/* Whatsapps List */}
								<Grid className={classes.filterField}>
									<WhatsappSelect
										selectedWhatsappIds={selectedWhatsappIds}
										onChange={values => setSelectedWhatsappIds(values)}
										whatsappsOptions={uniqueWhatsapps}
										styleSelectInputField={true}
									/>
								</Grid>

								{/* Queue List */}
								<Grid className={classes.filterField}>
									<QueueSelect
										selectedQueueIds={selectedQueueIds}
										onChange={values => setSelectedQueueIds(values)}
										queuesOptions={uniqueQueues}
									/>
								</Grid>

								{/* Category List */}
								<Grid className={classes.filterField}>
									<CategorySelect
										selectedCategoryIds={selectedCategoryIds}
										onChange={values => setSelectedCategoryIds(values)}
										categoriesOptions={uniqueCategories}
									/>
								</Grid>

								{/* Subqueue List */}
								<Grid className={classes.filterField}>
									<SubqueueSelect
										selectedSubqueueIds={selectedSubqueueIds}
										onChange={values => setSelectedSubqueueIds(values)}
										subqueuesOptions={uniqueSubqueues}
									/>
								</Grid>

								{/* Labels List */}
								<Grid className={classes.filterField}>
									<LabelSelect
										selectedLabelIds={selectedLabelIds}
										onChange={values => handleLabelFilterChange(values)}
										labelsOptions={allLabels}
									/>
								</Grid>

								{/* Ticket Type List */}
								<Grid className={classes.filterField}>
									<TicketTypeSelect
										selectedTicketTypes={selectedTicketTypes}
										onChange={values => setSelectedTicketTypes(values)}
										ticketTypesOptions={ticketTypes}
									/>
								</Grid>

								{/* Rating List */}
								<Grid className={classes.filterField}>
									<RatingsSelect
										selectedOptions={selectedRatings}
										onChange={values => setSelectedRatings(values)}
										styleSelectInputField={true}
									/>
								</Grid>

								{user.tenantId.toString() === "1" && (
									<>

									{/* Resale Serial Number Field */}
									<Grid className={classes.filterField}>
										<TextField
											variant="outlined"
											margin="normal"
											type="text"
											id="resaleSerialNumber"
											name="resaleSerialNumber"
											label={i18n.t("dashboard.fields.resaleSerialNumber")}
											onBlur={handleBlurResaleSerialNumber}
											className={classes.inputField}
										/>
									</Grid>

									{/* Serial Number Field */}
									<Grid className={classes.filterField}>
										<TextField
											variant="outlined"
											margin="normal"
											type="text"
											id="serialNumber"
											name="serialNumber"
											label={i18n.t("dashboard.fields.serialNumber")}
											onBlur={handleBlurSerialNumber}
											className={classes.inputField}
										/>
									</Grid>
									</>
								)}

								{(user.tenantId !== 1 && user.tenantId !== "1") && (

									// Serial Number Field
									<Grid className={classes.filterField}>
										<TextField
											variant="outlined"
											margin="normal"
											type="text"
											id="clientCode"
											name="clientCode"
											label={i18n.t("dashboard.fields.clientCode")}
											onBlur={handleBlurClientCode}
											className={classes.inputField}
										/>
									</Grid>
								)}

								{/* keyword */}
								<Grid className={`${classes.filterField} ${classes.messagesKeywordsGrid}`}>
									<TextField
										variant="outlined"
										margin="normal"
										type="text"
										id="messageKeywords"
										name="messageKeywords"
										label={i18n.t("dashboard.fields.messageKeyword")}
										onBlur={async(event) => await handleBlurMessageKeywords(event)}
										className={classes.inputField}
									/>
									
									<Tooltip title={i18n.t("dashboard.buttons.filterMessageKeywords")} placement="top-start" arrow>
										<Button
											onClick={() => handleSearchMessageKeywords(false)}
											className={`${classes.messageKeywordsButton} ${classes.floatingButton}`}
										>
											<Search />
										</Button>
									</Tooltip>
								</Grid>
							</Grid>



							{/* 
									***********
									** Cards **
									***********
							*/}
							<Grid item xs={12} className={classes.sectionContainer}>

								{/* Number of Chats */}
								<Grid className={classes.cardItem}>
									<Paper className={classes.customFixedHeightPaper}>
										<Typography component="h3" variant="h6" paragraph>
											{i18n.t("ticketsDashboard.amount")}
										</Typography>

										<Grid item>
											<Typography component="h1" variant="h4">
												{filteredClosedTickets.length}
											</Typography>
										</Grid>
									</Paper>
								</Grid>

								{/* Chat with Less Attendance Time (minutes) */}
								<Grid className={classes.cardItem}>
									<Paper className={classes.customFixedHeightPaper}>
											<Typography component="h3" variant="h6" paragraph>
												{i18n.t("ticketsDashboard.faster")}
											</Typography>

											<Grid item>
												<Typography component="h1" variant="h4">
													{GetFasterTicket()}
												</Typography>
											</Grid>
									</Paper>
								</Grid>

								{/* Chat with More Attendance Time (minutes) */}
								<Grid className={classes.cardItem}>
									<Paper className={classes.customFixedHeightPaper}>
										<Typography component="h3" variant="h6" paragraph>
											{i18n.t("ticketsDashboard.longer")}
										</Typography>

										<Grid item>
											<Typography component="h1" variant="h4">
												{GetLongerTicket()}
											</Typography>
										</Grid>
									</Paper>
								</Grid>
							</Grid>



							{/* 
									***********
									** Table **
									***********
							*/}
							<Grid item xs={12}>
								<div style={{ height: 500, width: '100%' }}>
									<DataGrid
										className={classes.ticketsTable}
										rows={filteredClosedTickets}
										columns={columns}
										rowsPerPageOptions={[7, 25, 50, 100]}
										checkboxSelection={true}
										disableColumnMenu={false}
									/>
								</div>
							</Grid>

							

							{/* 
								*********************
								** Generate Report **
								*********************
							*/}
							<Grid item xs={12} className={classes.flexCenteredGrid}>
								<Tooltip
									title={i18n.t("ticketsDashboard.tooltips.generateReport")}
									placement="top-start"
									arrow
								>
									<Button
										color="inherit"
										variant="outlined"
										className={`${classes.icon} ${classes.floatingButton}`}
										disabled={isTicketsDashboardBeingGenerated}
										onClick={handleGenerateTicketsReport}
									>
										<IconButton size="small" className={classes.icon} disabled={isTicketsDashboardBeingGenerated}>
											<PrintOutlined />
										</IconButton>
									</Button>
								</Tooltip>
							</Grid>
							</>
						)}
            
						{/* when there are no datas over the selected period, a message is shown */}
						{(viewClosedTickets === 0 || viewClosedTickets.length === 0) && (
							<Paper className={classes.dashboardContainer}>
								<div className={classes.emptyBoxContainer}>
									<img className={classes.emptyBoxImage} src={NoDataImg} alt="No Data" />
									<span className={classes.emptyBoxSpan}>{i18n.t("dashboard.emptyBox")}</span>
								</div>
							</Paper>
						)}
          </Grid>
        </Container>
      </div>
    </MainContainer>
		</>
  );
}

export default TicketsDashboard;