import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import UserSelect from "../UserSelect";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
  textField: { flex: 1, },

  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const NewInternalGroupModal = ({ modalOpen, onClose, internalGroupSelectedUserId = null, setTabOpen = null }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user: loggedInUser } = useContext(AuthContext);

  const [groupName, setGroupName] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const { allUsers } = useGlobalRegisters();
  const allUsersList = allUsers.filter(user => user.id !== loggedInUser.id);


  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (modalOpen && ![null, undefined].includes(internalGroupSelectedUserId)) {
      setSelectedUserIds(previousValue => [...previousValue, internalGroupSelectedUserId]);
    }
  }, [modalOpen, internalGroupSelectedUserId]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setGroupName("");
    setSelectedUserIds([]);
  };

  const handleGroupNameChange = (event) => { setGroupName(event.target.value); };

  const handleSelectedUsersChange = (users) => {
    setSelectedUserIds(users);
  };

  const transformSelectedUserIds = (loggedInUserId, selectedUserIds) => {
    return [loggedInUserId, ...selectedUserIds].map((item, index) => {
      return { userId: item, userProfile: index === 0 ? "superadmin" : "member" };
    });
  };

  const handleSaveInternalGroup = async (event) => {
    event.preventDefault();
    setLoading(true);

    if ([null, undefined].includes(groupName) || groupName.length < 3) {
      toast.info(i18n.t("newInternalGroupModal.toasts.groupNameInfo"));
    }

    else if (selectedUserIds.length < 2) {
      toast.info(i18n.t("newInternalGroupModal.toasts.selectedUserIdsInfo"));
    }

    else {
      try {
        const { data: ticket } = await api.post("/internalGroups", {
          name: groupName.trim(),
          userId: loggedInUser.id,
          userIdsInternalGroup: transformSelectedUserIds(loggedInUser.id, selectedUserIds),
          noteCreatedBy: loggedInUser.id
        });

        history.push(`/tickets/${ticket.id}`);

        if (![null, undefined].includes(setTabOpen)) setTabOpen("group");
        localStorage.setItem("tabOpenTickets", "group");

        handleClose();
      } catch (exception) {
        console.log("Handle Save Internal Group Exception:", exception);
        toast.error(exception);
      }
    }

    setLoading(false);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
      <form onSubmit={handleSaveInternalGroup}>
        <DialogTitle>
          {i18n.t("newInternalGroupModal.title")}
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            autoFocus
            fullWidth
            className={classes.textField}
            value={groupName}
            multiline={false}
            label={i18n.t("newInternalGroupModal.form.name")}
            name="name"
            variant="outlined"
            margin="dense"
            onChange={handleGroupNameChange}
          />

          <br />
          <Divider />
          <br />

          <UserSelect
            usersOptions={allUsersList}
            selectedUserIds={selectedUserIds}
            onChange={values => handleSelectedUsersChange(values)}
            styleSelectInputField={false}
            selectedItemsDisplayLimit={5}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            disabled={loading}
            variant="outlined"
            className={classes.floatingButton}
          >
            {i18n.t("newInternalGroupModal.buttons.cancel")}
          </Button>

          <ButtonWithSpinner
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
            className={classes.floatingButton}
          >
            {i18n.t("newInternalGroupModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewInternalGroupModal;
