import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	ListSubheader,
	makeStyles,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import { useSocketAuthenticated } from "../../context/Socket/SocketContext";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles((theme) => ({
  maxWidth: { width: "100%", padding: "0 0 20px 0", },

	floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const TransferTicketModal = ({ modalOpen, onClose, ticketid, ticketType, ticketWhatsappId }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const history = useHistory();
	const [whatsApps, setWhatsApps] = useState([]);

	const [loading, setLoading] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
	const [allowTransferTicketsToOfflineUsers, setAllowTransferTicketsToOfflineUsers] = useState();

	const [transferenceObservation, setTransferenceObservation] = useState("");
	
	const { onlineUsers, updateOnlineUsers } = useSocketAuthenticated();

	const { getSetting, allUsers, getWhatsappPriorization, isLoading } = useGlobalRegisters();

	
	
	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		if (modalOpen) {
			const updateOnlineUsersList = async () => await updateOnlineUsers();
			updateOnlineUsersList();
		}

		if (modalOpen && selectedUser) {
			const loadWhatsApps = async () => {
				const list = await getWhatsappPriorization(selectedUser);
				setWhatsApps(list);
			}
			
			loadWhatsApps();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalOpen, getWhatsappPriorization, selectedUser]);

	useEffect(() => {
		if (isLoading) return;

		if (modalOpen) {
			setAllowTransferTicketsToOfflineUsers(getSetting("allowTransferTicketsToOfflineUsers"));
		}
	}, [getSetting, isLoading, modalOpen]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleClose = () => {
		onClose();
		setSelectedUser(null);
		setTransferenceObservation("");
	};

	const handleSelectedUser = (e) => { setSelectedUser(e.target.value); }
	const handleTransferenceObservationChange = (event) => { setTransferenceObservation(event.target.value); };

	const handleSaveTicket = async (e) => {
		e.preventDefault();
		if (!ticketid) return;
		setLoading(true);

		if (selectedUser === null || selectedUser === "") {
			setLoading(false);
			toast.info(i18n.t("transferTicketModal.toast.userNotSelected"));
			return;
		}

		try {
			let data = {};
			
			if (selectedUser) { data.userId = selectedUser }
			if (selectedWhatsapp) { data.whatsappId = selectedWhatsapp; }
			if (transferenceObservation !== "") { data.transferenceObservation = transferenceObservation; }
			data.queueId = null;
			data.subqueueId = null;
			data.isTransfered = true;
			data.isBotInteractionFinished = true;
			
			await api.put(`/transferTicket/${ticketid}`, data);
			setLoading(false);
			handleClose();
			history.push(`/tickets`);
		} catch (error) {
			setLoading(false);
			console.log("Handle Save Ticket Error:", error);
			toastError(error);
		}
	};



	// 	************
	// 	** Return **
	// 	************
	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>

				<DialogContent dividers>
					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>{i18n.t("transferTicketModal.fieldLabel")}</InputLabel>
						<Select
							autoFocus
							value={selectedUser}
							onChange={(e) => handleSelectedUser(e)}
							label={i18n.t("transferTicketModal.fieldLabel")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>

							{/* ***---- Online Users ----*** */}
							<ListSubheader>{i18n.t("transferTicketModal.listSubheaders.onlineUsers")}</ListSubheader>
							{allUsers.map(user => {
								if (onlineUsers.some(onlineUser => onlineUser.userId === user.id)) {
									let queues = user.queues.length === 0 ? "" : "(";

									user.queues.forEach((queue, index) => {
										if (index === user.queues.length - 1) { queues += `${queue.name})`; }
										else if (index === user.queues.length - 2) { queues += `${queue.name} e `; }
										else { queues += `${queue.name}, `; }
									});

									return <MenuItem key={user.id} value={user.id}>{user.name} {queues}</MenuItem>;
								} else { return <></>; }
							})}

							{/* ***---- Offline Users ----*** */}
							<ListSubheader>{i18n.t("transferTicketModal.listSubheaders.offlineUsers")}</ListSubheader>
							{allUsers.map(user => {
								if (!onlineUsers.some(onlineUser => onlineUser.userId === user.id)) {
									let queues = user.queues.length === 0 ? "" : "(";

									user.queues.forEach((queue, index) => {
										if (index === user.queues.length - 1) { queues += `${queue.name})`; }
										else if (index === user.queues.length - 2) { queues += `${queue.name} e `; }
										else { queues += `${queue.name}, `; }
									});
									return (
										<MenuItem key={user.id} value={user.id} disabled={
											allowTransferTicketsToOfflineUsers === "disabled"
												? true
												: false
										}>{user.name} {queues}</MenuItem>
									);
								} else { return <></>; }
							})}
						</Select>
					</FormControl>
					
					{ticketType === 0 && selectedUser && selectedUser !== undefined && (
						<>
							<FormControl variant="outlined" className={classes.maxWidth} style={{ marginTop: 20 }}>
								<InputLabel>{i18n.t("transferTicketModal.fieldConnectionLabel")}</InputLabel>
								<Select
									value={selectedWhatsapp}
									onChange={(e) => setSelectedWhatsapp(e.target.value)}
									label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
								>
									{whatsApps.map((whasapp) => (
										<MenuItem key={whasapp.id} value={whasapp.id}>{whasapp.name}</MenuItem>
									))}
								</Select>
							</FormControl>

							<TextField
								value={transferenceObservation}
								as={TextField}
								label={i18n.t("transferTicketModal.fieldText")}
								autoFocus={false}
								name="observartion"
								variant="outlined"
								margin="dense"
								fullWidth
								className={classes.textField}
								multiline={true}
								minRows={5}
								maxRows={5}
								onChange={handleTransferenceObservationChange}
							/>
						</>
					)}
				</DialogContent>

				<DialogActions>
					<Button
						onClick={handleClose}
						color="inherit"
						disabled={loading}
						variant="outlined"
						className={classes.floatingButton}
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
						className={classes.floatingButton}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;
