import React, { useCallback, useState, useEffect, useReducer, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import PersonAddDisabledOutlinedIcon from '@material-ui/icons/PersonAddDisabledOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined';
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import SearchIcon from "@material-ui/icons/Search";
import ViewChatModal from "../../components/ViewChatModal";
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import api from "../../services/api";
import CardContactSkeleton from "../../components/CardContactSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal/";
import ConnectionSelect from "../../components/ConnectionSelect";
import ContactModal from "../../components/ContactModal";
import ContactTagsModal from "../../components/ContactTagsModal";
import ExportCSVModal from "../../components/ExportCSVModal";
import ImportCSVModal from "../../components/ImportCSVModal";
import LabelSelect from "../../components/LabelSelect";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import SocketContacts from "../../context/Socket/Listeners/SocketContacts";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import truncateString from "../../utils/truncateString";
import useWhatsApps from "../../hooks/useWhatsApps";
import WhatsappOfficialImg from "../../assets/whatsappOfficial.jpg";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) { state[contactIndex] = contact; }
      else { newContacts.push(contact); }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const searchParam = action.searchParam;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (!contact.name.toLowerCase().includes(searchParam)) { return [...state]; }
    else if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    }
    else { return [contact, ...state]; }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) { state.splice(contactIndex, 1); }
    return [...state];
  }

  if (action.type === "RESET") { return []; }
};

const useStyles = makeStyles((theme) => ({
  // *** Containers ***
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "5px",
    backgroundColor: theme.palette.background.default,
  },

  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    [theme.breakpoints.down("sm")]: { maxHeight: "75%", },
  },

  contactsList: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    height: "90%",
    [theme.breakpoints.down("sm")]: { maxHeight: "80%", },
  },

  cardOverviewContainer: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		flexWrap: "wrap",
		gap: "15px",
    ...theme.scrollbarStyles,
  },



  // *** Contacts Count ***
  contactCountContainer: { padding: "5px", textAlign: "center" },
  contactCount: { fontSize: "1.25em" },



  // *** View Options ***
  viewOptions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1em",
    padding: "0px 1em 0px 0px",
    width: "100%",
  },

  viewOptionsSubContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${theme.palette.text.primary}`,
    borderRadius: "5px",
    padding: "5px",
  },

  viewActiveOption: { color: theme.palette.primary.main, },
  viewOptionsDivider: { fontWeight: "bold", height: "100%", fontSize: "1.75em", },



  // *** Contact Card ***
  contactSlot: {
    border: `2px solid ${theme.palette.primary.pastel}`,
		borderRadius: "20px",
    padding: "5px",
    width: "250px",
		minHeight: "150px",
    maxHeight: "150px",
		transition: 'transform 0.3s',
		opacity: "0.90",
    display: "flex",
    flexDirection: "row",
		'&:hover': { transform: "translateY(-5px)", opacity: "1", },
  },

  profilePicContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px 0px 0px 20px",
    padding: "10px",
  },

  infoContainer: {
    width: "100%",
    borderRadius: "0px 20px 20px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  contactInfoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "5px",
    width: "100%",
    height: "70%",
    borderRadius: "0px 20px 0px 0px",
  },

  contactName: { fontSize: "1.25em", wordBreak: "break-word", },
  contactNumber: { fontSize: "1em", fontStyle: "italic", },
  contactEmail: { fontSize: "1em", fontStyle: "italic", },

  actionButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    height: "30%",
    borderRadius: "0px 0px 20px 0px",
  },


  // *** General ***
  buttonsContainer: {
    flex: "none",
    width: "90%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "10px",
    [theme.breakpoints.down("sm")]: { flexDirection: "column", width: "70%", },
  },

  icon: { color: theme.palette.text.primary, },

  floatingButton: {
    transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-5px)', },
  },

  actionButton: { "&:hover": { color: theme.palette.primary.main, }, },
  customTableCell: { display: "flex", alignItems: "center", justifyContent: "center", },

  contactLabelsField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "5px",
  },
}));

const Contacts = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [contactTagsModalOpen, setContactTagsModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [importCSVModalOpen, setImportCSVModalOpen] = useState(false);
  const [exportCSVModalOpen, setExportCSVModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [hasMore, setHasMore] = useState(false);

  const [selectedLabelIds, setSelectedLabelIds] = useState([]);

  const [selectedConnectionIds, setSelectedConnectionIds] = useState([]);

  const [selectedContactLabels, setSelectecContactLabels] = useState([]);

  const [contactsViewLayout, setContactsViewLayout] = useState(localStorage.getItem("contactsViewLayout"));
  const [typeOfContact, setTypeOfContact] = useState(localStorage.getItem("contactsViewType"));

  const [userQueues, setUserQueues] = useState([]);
  const [isBlockingContact, setIsBlockingContact] = useState(false);

  // ***---- Counts ----***
  const [contactsCount, setContactsCount] = useState(0);
  const [groupsCount, setGroupsCount] = useState(0);
  const [blockedContactsCount, setBlockedContactsCount] = useState(0);
  const [inactivatedContactsCount, setInactivatedContactsCount] = useState(0);

  // ***---- Confirmation Modals ----***
  const [confirmOpenDeleteContactModal, setConfirmOpenDeleteContactModal] = useState(false);
  const [confirmOpenActiveContactModal, setConfirmOpenActiveContactModal] = useState([false, null]); // [flag, contact id]
  const [confirmOpenBlockModal, setConfirmOpenBlockModal] = useState([false, null]); // [flag, contact id]

  // ***---- View Chat Modal ----***
  const [selectedViewChatModalContact, setSelectedViewChatContact] = useState({});
  const [viewChatModalOpen, setViewChatModalOpen] = useState(false);

  // ***---- Selected WhatsApp ----***
  const { whatsApps: whatsAppsList } = useWhatsApps();
  const [selectedWhatsappId, setSelectedWhatsappId] = useState("");

  // ***---- Selected Contact ----***
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedContactName, setSelectedContactName] = useState("");

  // Types:
  //
  //  - 0: contact
  //  - 1: group
  //  - 2: channel
  //
  const [selectedContactType, setSelectedContactType] = useState(0);

  // ***---- Open Ticket Confirmation Modal ----***
  const [openPrivateChatConversationModal, setOpenPrivateChatConversationModal] = useState(false);


  const { getSetting, allWhatsapps, allLabels, getUserQueuesIds } = useGlobalRegisters();


  //  ***************
  //  ** Callbacks **
  //  ***************
  const resetContactsListCallback = useCallback(async () => {
    //  ****************
    //  ** Reset List **
    //  ****************
    dispatch({ type: "RESET" });
    setPageNumber(1);

    //  ***********************
    //  ** Fetching Contacts **
    //  ***********************
    const apiCalls = [
      api.get("/contacts/", { params: { searchParam, pageNumber, labelIds: selectedLabelIds, whatsappIds: selectedConnectionIds, typeOfContact } }),
      api.get("/contactsCount"),
      api.get("/blockedContactsCount"),
      api.get("/groupsCount"),
      api.get("/inactivatedContactsCount")
    ];

    const [
      contactsResponse,
      contactsCountResponse,
      blockedContactsCountResponse,
      groupsCountResponse,
      inactivatedContactsCountResponse
    ] = await Promise.all(apiCalls);

    //  *******************
    //  ** Treating Info **
    //  *******************
    dispatch({ type: "LOAD_CONTACTS", payload: contactsResponse.data.contacts });
    setHasMore(contactsResponse.data.hasMore);

    setContactsCount(contactsCountResponse.data);
    setBlockedContactsCount(blockedContactsCountResponse.data);
    setGroupsCount(groupsCountResponse.data);
    setInactivatedContactsCount(inactivatedContactsCountResponse.data);
  }, [searchParam, pageNumber, selectedLabelIds, selectedConnectionIds, typeOfContact]);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUserQueuesIds = async () => {
        try {
          const userQueuesIds = await getUserQueuesIds(user.id);
          setUserQueues(userQueuesIds);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log("Contacts Use Effect 2 Error:", error);
          toastError(error);
        }
      };
      fetchUserQueuesIds();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [getUserQueuesIds, user.id]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, selectedLabelIds, selectedConnectionIds, typeOfContact]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(async () => {
      const fetchContacts = async () => {
        try {
          const apiCalls = [api.get("/contacts/", { params: { searchParam, pageNumber, labelIds: selectedLabelIds, whatsappIds: selectedConnectionIds, typeOfContact } })];
          const [contactsResponse] = await Promise.all(apiCalls);
      
          dispatch({ type: "LOAD_CONTACTS", payload: contactsResponse.data.contacts });
          setHasMore(contactsResponse.data.hasMore);
          setLoading(false);
        } catch (error) {
          console.log("Contacts Use Effect 3 Error:", error);
          toastError(error);
        }
      };
      await fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, selectedLabelIds, selectedConnectionIds, typeOfContact]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchCounts = async () => {
        try {
          const apiCalls = [
            api.get("/contactsCount"),
            api.get("/blockedContactsCount"),
            api.get("/groupsCount"),
            api.get("/inactivatedContactsCount")
          ];
          const [
            contactsCountResponse,
            blockedContactsCountResponse,
            groupsCountResponse,
            inactivatedContactsCountResponse
          ] = await Promise.all(apiCalls);

          setContactsCount(contactsCountResponse.data);
			    setBlockedContactsCount(blockedContactsCountResponse.data);
			    setGroupsCount(groupsCountResponse.data);
          setInactivatedContactsCount(inactivatedContactsCountResponse.data);
        } catch (error) {
          console.log("Contacts Use Effect 4 Error:", error);
          toastError(error);
        }
      };
      fetchCounts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [typeOfContact]);

  useEffect(() => {
    if (user.whatsapp?.id) setSelectedWhatsappId(user.whatsapp.id);
    else if (whatsAppsList.length === 1) setSelectedWhatsappId(whatsAppsList[0].id);
  }, [user, whatsAppsList]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenPrivateChatConversationModal = (contact) => {
    setOpenPrivateChatConversationModal(true);
    setSelectedContactId(contact.id);
    setSelectedContactName(contact.name);

    if (contact.isGroup) setSelectedContactType(1);
    else if (contact.isChannel) setSelectedContactType(2);
    else setSelectedContactType(0);
  };

  const handleClosePrivateChatConversationModal = () => {
    setOpenPrivateChatConversationModal(false);
    setSelectedWhatsappId("");
    setSelectedContactId(null);
    setSelectedContactName("");
    setSelectedContactType(0);
  };

  const handleSavePrivateChatConversation = (selectedContactId, selectedContactType) => {
    if (selectedContactType === 0 && (!selectedWhatsappId || selectedWhatsappId === undefined)) {
      toast.info(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
    }
    else {
      if (selectedContactType === 0) handleSaveTicket(selectedContactId, selectedWhatsappId);
      else if (selectedContactType === 1) handleSaveGroupTicket(selectedContactId);
      else handleSaveChannelTicket(selectedContactId);
    }
  };

  const handleOpenExportCSVModal = () => { setExportCSVModalOpen(true); };
  const handleCloseExportCSVModal = () => { setExportCSVModalOpen(false); }

  const handleOpenImportCSVModal = () => { setImportCSVModalOpen(true); };
  const handleCloseImportCSVModal = () => { setImportCSVModalOpen(false); };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleOpenContactTagsModal = (contactLabels) => {
    setContactTagsModalOpen(true);
    setSelectecContactLabels(contactLabels);
  };

  const handleCloseContactTagsModal = () => {
    setContactTagsModalOpen(false);
    setSelectecContactLabels([]);
  };

  const handleSaveGroupTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);

    try {
      const groupSetting = getSetting("allowGroups");

      if (groupSetting === "disabled") {
        toast.info(i18n.t("contacts.toasts.groupsNotEnabled"));
      }
      else {
        const { data: ticket } = await api.post("/tickets", {
          contactId: contactId,
          userId: null,
          status: "group",
          isCreatedByUser: true
        });
        localStorage.setItem("tabOpenTickets", ticket.status);
        history.push(`/tickets/${ticket.id}`);
      }
    } catch (error) {
      console.log("Handle Save Group Ticket Error:", error);
      toastError(error);
    }
    
    setLoading(false);
    setSelectedWhatsappId("");
    setSelectedContactId(null);
    setSelectedContactName("");
    setSelectedContactType(0);
  };

  const handleSaveChannelTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);

    try {
      const channelSetting = getSetting("allowChannels");

      if (channelSetting === "disabled") { toast.info(i18n.t("contacts.toasts.channelsNotEnabled")); }
      else {
        const { data: ticket } = await api.post("/tickets", {
          contactId: contactId,
          userId: null,
          status: "channel",
          isCreatedByUser: true
        });

        localStorage.setItem("tabOpenTickets", ticket.status);
        history.push(`/tickets/${ticket.id}`);
      }
    } catch (error) {
      console.log("Handle Save Channel Ticket Error:", error);
      toastError(error);
    }

    setLoading(false);
    setSelectedWhatsappId("");
    setSelectedContactId(null);
    setSelectedContactName("");
    setSelectedContactType(0);
  };

  const handleSaveTicket = async (contactId, selectedWhatsappId) => {
    if (!contactId) return;
    setLoading(true);

    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user?.id,
        status: "open",
        isCreatedByUser: true,
        isBotInteractionFinished: true,
        whatsappId: selectedWhatsappId
      });

      const pendingTicketCondition = ticket.status === "pending" && (ticket.queueId === null || userQueues.includes(ticket.queueId));
      const openTicketAndAdminUserCondition = ticket.status === "open" && user.profile === "admin" && getSetting("adminCanViewOtherChats") === "enabled";
      const openTicketAndCommonUserCondition = ticket.status === "open" && ticket.userId === user.id;

      // *** User can see Ticket ***
      if ((pendingTicketCondition) || (openTicketAndAdminUserCondition) || (openTicketAndCommonUserCondition)) {
        localStorage.setItem("tabOpenTickets", ticket.status);
        history.push(`/tickets/${ticket.id}`);
      }

      // *** User cannot see Ticket ***
      else {

        // *** Pending Ticket in another Queue ***
        if (ticket.status === "pending") {
          toast.info(`
            ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
            ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_QUEUE")}
            ${ticket.queue?.name}
          `);
        }
        
        // *** Ticket with another User ***
        else {
          toast.info(`
            ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
            ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_USER")}
            ${ticket.user?.name}
          `);
        }
      }
    } catch (error) {
      console.log("Handle Save Ticket Error:", error);
      toastError(error);
    }

    setLoading(false);
    setSelectedWhatsappId("");
    setSelectedContactId(null);
    setSelectedContactName("");
    setSelectedContactType(0);
  };

  const resetContactsList = async () => {
    //  ****************
    //  ** Reset List **
    //  ****************
    dispatch({ type: "RESET" });
    setPageNumber(1);

    //  ***********************
    //  ** Fetching Contacts **
    //  ***********************
    const apiCalls = [
      api.get("/contacts/", { params: { searchParam, pageNumber, labelIds: selectedLabelIds, whatsappIds: selectedConnectionIds, typeOfContact } }),
      api.get("/contactsCount"),
      api.get("/blockedContactsCount"),
      api.get("/groupsCount"),
      api.get("/inactivatedContactsCount")
    ];

    const [
      contactsResponse,
      contactsCountResponse,
      blockedContactsCountResponse,
      groupsCountResponse,
      inactivatedContactsCountResponse
    ] = await Promise.all(apiCalls);

    //  *******************
    //  ** Treating Info **
    //  *******************
    dispatch({ type: "LOAD_CONTACTS", payload: contactsResponse.data.contacts });
    setHasMore(contactsResponse.data.hasMore);

    setContactsCount(contactsCountResponse.data);
    setBlockedContactsCount(blockedContactsCountResponse.data);
    setGroupsCount(groupsCountResponse.data);
    setInactivatedContactsCount(inactivatedContactsCountResponse.data);
  };

  const handleBlockOrUnblockContact = async (contactId, isBlocking) => {
    setIsBlockingContact(isBlocking);
    setConfirmOpenBlockModal([true, contactId]);
  };

  const handleBlockContact = async (contactId) => {
    if (!contactId) return;
    setLoading(true);

    try {
      await api.put(`/blockContact/${contactId}`);
      await resetContactsList();
    } catch (error) {
      console.log("Handle Block Contact Error:", error);
      toastError(error);
    }

    setLoading(false);
    setSearchParam("");
  };

  const handleUnblockContact = async (contactId) => {
    if (!contactId) return;
    setLoading(true);

    try {
      await api.put(`/unblockContact/${contactId}`);
      await resetContactsList();
    } catch (error) {
      console.log("Handle Unblock Contact Error:", error);
      toastError(error);
    }

    setLoading(false);
    setSearchParam("");
  };

  const handleActiveContact = async (contactId) => {
    if (!contactId) return;
    setLoading(true);

    try {
      await api.put(`/activateContact/${contactId}`);
      await resetContactsList();
    } catch (error) {
      console.log("Handle Active Contact Error:", error);
      toastError(error);
    }

    setLoading(false);
    setSearchParam("");
  };

  const hadleEditContact = (contact) => {
    setSelectedContactId(contact.id);
    setContactModalOpen(true);

    let contactType;
    if (contact.isGroup) contactType = 1;
    else if (contact.isChannel) contactType = 2;
    else contactType = 0;
    setSelectedContactType(contactType);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`, { data: { typeOfContact } });
      await resetContactsList();
    } catch (error) {
      console.log("Handle Delete Contact Error:", error);
      toastError(error);
    }

    setDeletingContact(null);
    setSearchParam("");
    setSelectedLabelIds([]);
    setSelectedConnectionIds([]);
    setPageNumber(1);
  };

  const loadMore = () => { setPageNumber((prevState) => prevState + 1); };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) { loadMore(); }
  };

  const handleLabelFilterChange = (values) => { setSelectedLabelIds(values); };
  const handleConnectionFilterChange = (values) => { setSelectedConnectionIds(values); };
  const handleSearch = (event) => { setSearchParam(event.target.value.toLowerCase()); };

  const handleContactsView = (layout) => {
    localStorage.setItem("contactsViewLayout", layout);
    setContactsViewLayout(layout);
  };

  const handleTypeOfContactView = (type) => {
    localStorage.setItem("contactsViewType", type);
    setTypeOfContact(type);
  }

  const handleOpenViewChatModal = (contact) => {
    setSelectedViewChatContact({
      id: contact.id,
      name: contact.name,
      isGroup: contact.isGroup,
      isChannel: contact.isChannel
    });

    setViewChatModalOpen(true);
  };

  const handleCloseViewChatModal = () => {
    setViewChatModalOpen(false);
    setSelectedViewChatContact({});
  };




  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      <SocketContacts
        dispatch={dispatch}
        searchParam={searchParam}
        resetContactsListCallback={resetContactsListCallback}
      />

      {/* ***---- View Chat Modal ----*** */}
      <ViewChatModal
        open={viewChatModalOpen}
        onClose={handleCloseViewChatModal}
        contact={selectedViewChatModalContact}
        handleOpenPrivateChatConversationModal={handleOpenPrivateChatConversationModal}
      />

      {/* ***---- Contact Modal ----*** */}
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        contactId={selectedContactId}
        setContactsCount={setContactsCount}
        contactType={selectedContactType}
      />

      {/* ***---- Open Private Chat ----*** */}
      <ConfirmationModal
        title={`${i18n.t("contacts.confirmationModal.openPrivateChat.title")} ${selectedContactName}?`}
        open={openPrivateChatConversationModal}
        onClose={handleClosePrivateChatConversationModal}
        onConfirm={() => handleSavePrivateChatConversation(selectedContactId, selectedContactType)}
      >
        {selectedContactType === 0 && (
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
            <Select
              value={selectedWhatsappId}
              onChange={(e) => setSelectedWhatsappId(e.target.value)}
              label={i18n.t("newTicketModal.connection")}
              fullWidth
            >
              <MenuItem value={""}>&nbsp;</MenuItem>
              {whatsAppsList.map((whatsapp) => (
                <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </ConfirmationModal>
      
      {/* ***---- Contact Tags ----*** */}
      <ContactTagsModal
        open={contactTagsModalOpen}
        onClose={handleCloseContactTagsModal}
        labelsList={selectedContactLabels}
      />

      {/* ***---- Export Contacts to CSV ----*** */}
      <ExportCSVModal open={exportCSVModalOpen} onClose={handleCloseExportCSVModal} />

      {/* ***---- Import Contacts from CSV ----*** */}
      <ImportCSVModal open={importCSVModalOpen} onClose={handleCloseImportCSVModal} />

      {/* ***---- Delete Contact ----*** */}
      <ConfirmationModal
        title={
          typeOfContact === "0" || typeOfContact === "3"
            ? i18n.t("contacts.confirmationModal.inactiveTitle")
            : i18n.t("contacts.confirmationModal.deleteTitle")
        }
        open={confirmOpenDeleteContactModal}
        onClose={() => {
          setConfirmOpenDeleteContactModal(false);
          setDeletingContact(null);
        }}
        onConfirm={(e) => handleDeleteContact(deletingContact.id)}
      >
        {deletingContact && (typeOfContact === "0" || typeOfContact === "3") && i18n.t("contacts.confirmationModal.inactiveMessage")}
        {deletingContact && typeOfContact === "1" && i18n.t("contacts.confirmationModal.deleteGroupMessage")}
        {deletingContact && typeOfContact === "2" && i18n.t("contacts.confirmationModal.deleteChannelMessage")}
        {!deletingContact && i18n.t("contacts.confirmationModal.importMessage")}
      </ConfirmationModal>

      {/* ***---- Block and Unblock Contacts ----*** */}
      <ConfirmationModal
        title={
          isBlockingContact
            ? i18n.t("contacts.confirmationModal.blockTitle")
            : i18n.t("contacts.confirmationModal.unblockTitle")
        }
        open={confirmOpenBlockModal[0]}
        onClose={() => setConfirmOpenBlockModal([false, null])}
        onConfirm={(e) =>
          isBlockingContact
            ? handleBlockContact(confirmOpenBlockModal[1])
            : handleUnblockContact(confirmOpenBlockModal[1])
        }
      >
        {
          isBlockingContact
            ? i18n.t("contacts.confirmationModal.blockMessage")
            : i18n.t("contacts.confirmationModal.unblockMessage")
        }
      </ConfirmationModal>

      {/* ***---- Active Contacts ----*** */}
      <ConfirmationModal
        title={i18n.t("contacts.confirmationModal.activeTitle")}
        open={confirmOpenActiveContactModal[0]}
        onClose={() => setConfirmOpenActiveContactModal([false, null])}
        onConfirm={() => handleActiveContact(confirmOpenActiveContactModal[1])}
      >
        {i18n.t("contacts.confirmationModal.activeMessage")}
      </ConfirmationModal>

      <MainHeader>
        <Title>{i18n.t("contacts.title")}</Title>

        <div className={classes.buttonsContainer}>

          {!isSmallScreen && (
            <>
              <Tooltip title={i18n.t("contacts.buttons.exportCSV")} placement="top-start" arrow>
                <Button onClick={handleOpenExportCSVModal} className={classes.floatingButton}>
                  <PublishIcon />
                </Button>
              </Tooltip>


              <Tooltip title={i18n.t("contacts.buttons.importCSV")} placement="top-start" arrow>
                <Button onClick={handleOpenImportCSVModal} className={classes.floatingButton}>
                  <SaveAltOutlinedIcon />
                </Button>
              </Tooltip>
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
            className={classes.floatingButton}
          >
            {i18n.t("contacts.buttons.add")}
          </Button>
        </div>
      </MainHeader>

      <br />

      <Container className={classes.filterContainer}>
        <TextField
          color="success"
          placeholder={i18n.t("contacts.searchPlaceholder")}
          type="search"
          value={searchParam}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "gray" }} />
              </InputAdornment>
            ),
          }}
        />

        <ConnectionSelect
          selectedConnectionIds={selectedConnectionIds}
          onChange={values => handleConnectionFilterChange(values)}
          connectionOptions={allWhatsapps}
        />

        <LabelSelect
          selectedLabelIds={selectedLabelIds}
          onChange={values => handleLabelFilterChange(values)}
          labelsOptions={allLabels}
        />
      </Container>

      <br />

      <Paper className={classes.mainPaper} variant="outlined">
        {typeOfContact === "0" && (
          <div className={classes.contactCountContainer}>
            <Typography className={classes.contactCount}><b>{contactsCount}</b> {i18n.t("contacts.messages.contacts")}</Typography>
          </div>
        )}

        {typeOfContact === "1" && (
          <div className={classes.contactCountContainer}>
            <Typography className={classes.contactCount}><b>{groupsCount}</b> {i18n.t("contacts.messages.contacts")}</Typography>
          </div>
        )}

        {typeOfContact === "3" && (
          <div className={classes.contactCountContainer}>
            <Typography className={classes.contactCount}><b>{blockedContactsCount}</b> {i18n.t("contacts.messages.contacts")}</Typography>
          </div>
        )}

        {typeOfContact === "4" && (
          <div className={classes.contactCountContainer}>
            <Typography className={classes.contactCount}><b>{inactivatedContactsCount}</b> {i18n.t("contacts.messages.contacts")}</Typography>
          </div>
        )}

        <br />

        {/* 
          ******************
          ** View Options **
          ******************
        */}
        <div className={classes.viewOptions}>



          {/* 
            **************************
            ** Type of Contact View **
            **************************          
          */}
          <div className={classes.viewOptionsSubContainer}>
            <Tooltip title={i18n.t("contacts.tooltips.contactsLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleTypeOfContactView("0")}
              >
                <PersonOutlinedIcon className={typeOfContact === "0" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>

            <span className={classes.viewOptionsDivider}>|</span>

            <Tooltip title={i18n.t("contacts.tooltips.groupsLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleTypeOfContactView("1")}
              >
                <GroupOutlinedIcon className={typeOfContact === "1" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>

            <span className={classes.viewOptionsDivider}>|</span>

            <Tooltip title={i18n.t("contacts.tooltips.blockedLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleTypeOfContactView("3")}
              >
                <BlockOutlinedIcon className={typeOfContact === "3" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>

            <span className={classes.viewOptionsDivider}>|</span>

            <Tooltip title={i18n.t("contacts.tooltips.inactivatedLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleTypeOfContactView("4")}
              >
                <PersonAddDisabledOutlinedIcon className={typeOfContact === "4" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>
          </div>



          {/* 
            *****************
            ** Layout View **
            *****************          
          */}
          <div className={classes.viewOptionsSubContainer}>
            <Tooltip title={i18n.t("contacts.tooltips.listLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleContactsView("list")}
              >
                <ViewHeadlineIcon className={contactsViewLayout === "list" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>

            <span className={classes.viewOptionsDivider}>|</span>

            <Tooltip title={i18n.t("contacts.tooltips.cardLayout")} placement="top-start" arrow>
              <IconButton
                className={classes.icon}
                size="small"
                onClick={() => handleContactsView("card")}
              >
                <ViewModuleIcon className={contactsViewLayout === "card" ? `${classes.viewActiveOption} ${classes.actionButton}` : `${classes.actionButton}`} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <br />

        <div className={classes.contactsList} onScroll={handleScroll}>
          {/* 
            **************************
            ** List View - Contacts **
            **************************
          */}
          {contactsViewLayout === "list" && typeOfContact === "0" && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {isSmallScreen && (<TableCell align="center">{i18n.t("contacts.table.actions")}</TableCell>)}
                  <TableCell padding="checkbox" />
                  <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                  <TableCell>{i18n.t("contacts.table.label")}</TableCell>
                  <TableCell align="center">{i18n.t("contacts.table.whatsapp")}</TableCell>
                  <TableCell align="center">{i18n.t("contacts.table.email")}</TableCell>
                  {!isSmallScreen && (<TableCell align="center">{i18n.t("contacts.table.actions")}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {contacts.map((contact) => (
                    <TableRow key={contact.id}>
                      {isSmallScreen && (
                        <TableCell align="center">

                          {/* ***---- Open Private Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenPrivateChatConversationModal(contact)}
                          >
                            <WhatsAppIcon className={classes.actionButton} />
                          </IconButton>

                          
                          {/* ***---- View Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenViewChatModal(contact)}
                          >
                            <VisibilityOutlinedIcon className={classes.actionButton} />
                          </IconButton>

                          {/* ***---- Block and Edit ----*** */}
                          {!["WhatsApp Official ✔️", "Meta AI"].includes(contact.name) && (
                            <>
                            {/* ***---- Block ----*** */}
                            <Can
                              role={user.profile}
                              perform="contacts-page:blockContact"
                              yes={() => (
                                <IconButton
                                  className={classes.icon}
                                  size="small"
                                  onClick={() => handleBlockOrUnblockContact(contact.id, true)}
                                >
                                  <BlockOutlinedIcon className={classes.actionButton} />
                                </IconButton>
                              )}
                            />

                            {/* ***---- Edit ----*** */}
                            <IconButton
                              className={classes.icon}
                              size="small"
                              onClick={() => hadleEditContact(contact)}
                            >
                              <EditIcon className={classes.actionButton} />
                            </IconButton>
                            </>
                          )}

                          {/* ***---- Delete ----*** */}
                          <Can
                            role={user.profile}
                            perform="contacts-page:deleteContact"
                            yes={() => (
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={(e) => {
                                  setConfirmOpenDeleteContactModal(true);
                                  setDeletingContact(contact);
                                }}
                              >
                                <DeleteOutlineIcon className={classes.actionButton} />
                              </IconButton>
                            )}
                          />
                        </TableCell>
                      )}

                      <TableCell style={{ paddingRight: 0 }}><Avatar src={contact.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact.profilePicUrl} /></TableCell>
                      <TableCell>{contact.name}</TableCell>
                      
                      <TableCell>
                        <div>
                          <Accordion>
                            <AccordionSummary
                              id="labels-accordion-header"
                              aria-controls="labels-accordion-header"
                              expandIcon={<ExpandMoreIcon />}
                              TransitionProps={{ unmountOnExit: true }}
                            >
                              <Typography className={classes.accordionHeading}>
                                {i18n.t("contacts.accordion.title")}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.contactLabelsField}>
                              {/* Contact has labels */}
                              {contact.labels && contact.labels.map((label) => (
                                <span style={{ border: `2px solid ${label.color}`, padding: "7px", borderRadius: "20px" }}>
                                  {label.name}
                                </span>
                              ))}

                              {/* Contact does not have labels */}
                              {((contact.labels && contact.labels.length === 0)
                              || (contact.labels === null || contact.labels === undefined)) && (
                                <Typography>
                                  {i18n.t("contacts.accordion.noLabels")}
                                </Typography>)
                              }
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </TableCell>

                      <TableCell align="center">{contact.number}</TableCell>
                      <TableCell align="center">{contact.email}</TableCell>

                      {!isSmallScreen && (
                        <TableCell align="center">

                          {/* ***---- Open Private Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenPrivateChatConversationModal(contact)}
                          >
                            <WhatsAppIcon className={classes.actionButton} />
                          </IconButton>
                          

                          {/* ***---- View Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenViewChatModal(contact)}
                          >
                            <VisibilityOutlinedIcon className={classes.actionButton} />
                          </IconButton>

                          {/* ***---- Block and Edit ----*** */}
                          {!["WhatsApp Official ✔️", "Meta AI"].includes(contact.name) && (
                            <>
                              {/* ***---- Block ----*** */}
                              <Can
                                role={user.profile}
                                perform="contacts-page:blockContact"
                                yes={() => (
                                  <IconButton
                                    className={classes.icon}
                                    size="small"
                                    onClick={() => handleBlockOrUnblockContact(contact.id, true)}
                                  >
                                    <BlockOutlinedIcon className={classes.actionButton} />
                                  </IconButton>
                                )}
                              />

                              {/* ***---- Edit ----*** */}
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={() => hadleEditContact(contact)}
                              >
                                <EditIcon className={classes.actionButton} />
                              </IconButton>
                            </>
                          )}

                          {/* ***---- Delete ----*** */}
                          <Can
                            role={user.profile}
                            perform="contacts-page:deleteContact"
                            yes={() => (
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={(e) => {
                                  setConfirmOpenDeleteContactModal(true);
                                  setDeletingContact(contact);
                                }}
                              >
                                <DeleteOutlineIcon className={classes.actionButton} />
                              </IconButton>
                            )}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton avatar columns={4} />}
                </>
              </TableBody>
            </Table>
          )}



          {/* 
            ************************
            ** List View - Groups **
            ************************
          */}
          {contactsViewLayout === "list" && typeOfContact === "1" && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {isSmallScreen && (<TableCell align="center">{i18n.t("contacts.table.actions")}</TableCell>)}
                  <TableCell padding="checkbox" />
                  <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                  {!isSmallScreen && (<TableCell align="center">{i18n.t("contacts.table.actions")}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {contacts.map((contact) => (
                    <TableRow key={contact.id}>

                      {isSmallScreen && (
                        <TableCell align="center">

                          {/* ***---- Open Private Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenPrivateChatConversationModal(contact)}
                          >
                            <WhatsAppIcon className={classes.actionButton} />
                          </IconButton>

                          {/* ***---- View Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenViewChatModal(contact)}
                          >
                            <VisibilityOutlinedIcon className={classes.actionButton} />
                          </IconButton>

                          {/* ***---- Delete ----*** */}
                          <Can
                            role={user.profile}
                            perform="contacts-page:deleteContact"
                            yes={() => (
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={(e) => {
                                  setConfirmOpenDeleteContactModal(true);
                                  setDeletingContact(contact);
                                }}
                              >
                                <DeleteOutlineIcon className={classes.actionButton} />
                              </IconButton>
                            )}
                          />
                        </TableCell>
                      )}

                      <TableCell style={{ paddingRight: 0 }}><Avatar src={contact.profilePicUrl} /></TableCell>
                      <TableCell>{contact.name}</TableCell>

                      {!isSmallScreen && (
                        <TableCell align="center">

                          {/* ***---- Open Private Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenPrivateChatConversationModal(contact)}
                          >
                            <WhatsAppIcon className={classes.actionButton} />
                          </IconButton>

                          {/* ***---- View Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenViewChatModal(contact)}
                          >
                            <VisibilityOutlinedIcon className={classes.actionButton} />
                          </IconButton>

                          {/* ***---- Delete ----*** */}
                          <Can
                            role={user.profile}
                            perform="contacts-page:deleteContact"
                            yes={() => (
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={(e) => {
                                  setConfirmOpenDeleteContactModal(true);
                                  setDeletingContact(contact);
                                }}
                              >
                                <DeleteOutlineIcon className={classes.actionButton} />
                              </IconButton>
                            )}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton avatar columns={1} />}
                </>
              </TableBody>
            </Table>
          )}



          {/* 
            **********************************
            ** List View - Blocked Contacts **
            **********************************
          */}
          {contactsViewLayout === "list" && typeOfContact === "3" && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {isSmallScreen && (<TableCell align="center">{i18n.t("contacts.table.actions")}</TableCell>)}
                  <TableCell padding="checkbox" />
                  <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                  <TableCell>{i18n.t("contacts.table.label")}</TableCell>
                  <TableCell align="center">{i18n.t("contacts.table.whatsapp")}</TableCell>
                  <TableCell align="center">{i18n.t("contacts.table.email")}</TableCell>
                  {!isSmallScreen && (<TableCell align="center">{i18n.t("contacts.table.actions")}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {contacts.map((contact) => (
                    <TableRow key={contact.id}>
                      {isSmallScreen && (
                        <TableCell align="center">
                          

                          {/* ***---- View Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenViewChatModal(contact)}
                          >
                            <VisibilityOutlinedIcon className={classes.actionButton} />
                          </IconButton>

                          {/* ***---- Unblock and Edit ----*** */}
                          {!["WhatsApp Official ✔️", "Meta AI"].includes(contact.name) && (
                            <>
                              {/* ***---- Unblock ----*** */}
                              <Can
                                role={user.profile}
                                perform="contacts-page:unblockContact"
                                yes={() => (
                                  <IconButton
                                    className={classes.icon}
                                    size="small"
                                    onClick={() => handleBlockOrUnblockContact(contact.id, false)}
                                  >
                                    <BlockOutlinedIcon className={classes.actionButton} />
                                  </IconButton>
                                )}
                              />

                              {/* ***---- Edit ----*** */}
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={() => hadleEditContact(contact)}
                              >
                                <EditIcon className={classes.actionButton} />
                              </IconButton>
                            </>
                          )}

                          {/* ***---- Delete ----*** */}
                          <Can
                            role={user.profile}
                            perform="contacts-page:deleteContact"
                            yes={() => (
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={(e) => {
                                  setConfirmOpenDeleteContactModal(true);
                                  setDeletingContact(contact);
                                }}
                              >
                                <DeleteOutlineIcon className={classes.actionButton} />
                              </IconButton>
                            )}
                          />
                        </TableCell>
                      )}

                      <TableCell style={{ paddingRight: 0 }}><Avatar src={contact.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact.profilePicUrl} /></TableCell>
                      <TableCell>{contact.name}</TableCell>
                      <TableCell>
                        <div>
                          <Accordion>
                            <AccordionSummary
                              id="labels-accordion-header"
                              aria-controls="labels-accordion-header"
                              expandIcon={<ExpandMoreIcon />}
                              TransitionProps={{ unmountOnExit: true }}
                            >
                              <Typography className={classes.accordionHeading}>
                                {i18n.t("contacts.accordion.title")}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.contactLabelsField}>
                              {/* Contact has labels */}
                              {contact.labels && contact.labels.map((label) => (
                                <span style={{ border: `2px solid ${label.color}`, padding: "7px", borderRadius: "20px" }}>
                                  {label.name}
                                </span>
                              ))}

                              {/* Contact does not have labels */}
                              {((contact.labels && contact.labels.length === 0)
                              || (contact.labels === null || contact.labels === undefined)) && (
                                <Typography>
                                  {i18n.t("contacts.accordion.noLabels")}
                                </Typography>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </TableCell>
                      <TableCell align="center">{contact.number}</TableCell>
                      <TableCell align="center">{contact.email}</TableCell>

                      {!isSmallScreen && (
                        <TableCell align="center">

                          {/* ***---- View Chat ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => handleOpenViewChatModal(contact)}
                          >
                            <VisibilityOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                          
                          
                          {/* ***---- Unblock and Edit ----*** */}
                          {!["WhatsApp Official ✔️", "Meta AI"].includes(contact.name) && (
                            <>
                              {/* ***---- Unblock ----*** */}
                              <Can
                                role={user.profile}
                                perform="contacts-page:unblockContact"
                                yes={() => (
                                  <IconButton
                                    className={classes.icon}
                                    size="small"
                                    onClick={() => handleBlockOrUnblockContact(contact.id, false)}
                                  >
                                    <BlockOutlinedIcon className={classes.actionButton} />
                                  </IconButton>
                                )}
                              />

                              {/* ***---- Edit ----*** */}
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={() => hadleEditContact(contact)}
                              >
                                <EditIcon className={classes.actionButton} />
                              </IconButton>
                            </>
                          )}

                          {/* ***---- Delete ----*** */}
                          <Can
                            role={user.profile}
                            perform="contacts-page:deleteContact"
                            yes={() => (
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={(e) => {
                                  setConfirmOpenDeleteContactModal(true);
                                  setDeletingContact(contact);
                                }}
                              >
                                <DeleteOutlineIcon className={classes.actionButton} />
                              </IconButton>
                            )}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton avatar columns={4} />}
                </>
              </TableBody>
            </Table>
          )}



          {/* 
            **************************************
            ** List View - Inactivated Contacts **
            **************************************
          */}
          {contactsViewLayout === "list" && typeOfContact === "4" && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  {isSmallScreen && (<TableCell align="center">{i18n.t("contacts.table.actions")}</TableCell>)}
                  <TableCell padding="checkbox" />
                  <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                  <TableCell>{i18n.t("contacts.table.label")}</TableCell>
                  <TableCell align="center">{i18n.t("contacts.table.whatsapp")}</TableCell>
                  <TableCell align="center">{i18n.t("contacts.table.email")}</TableCell>
                  {!isSmallScreen && (<TableCell align="center">{i18n.t("contacts.table.actions")}</TableCell>)}
                </TableRow>
              </TableHead>

              <TableBody>
                {contacts.map((contact) => (
                  <TableRow key={contact.id}>
                    {isSmallScreen && (
                      <TableCell align="center">
                        
                        {/* ***---- View Chat ----*** */}
                        <IconButton
                          className={classes.icon}
                          size="small"
                          onClick={() => handleOpenViewChatModal(contact)}
                        >
                          <VisibilityOutlinedIcon className={classes.actionButton} />
                        </IconButton>

                        {/* ***---- Active ----*** */}
                        <IconButton
                          className={classes.icon}
                          size="small"
                          onClick={() => setConfirmOpenActiveContactModal([true, contact.id])}
                        >
                          <ReplyOutlinedIcon className={classes.actionButton} />
                        </IconButton>
                      </TableCell>
                    )}

                    <TableCell style={{ paddingRight: 0 }}><Avatar src={contact.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact.profilePicUrl}/></TableCell>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell>
                      <div>
                        <Accordion>
                          <AccordionSummary
                            id="labels-accordion-header"
                            aria-controls="labels-accordion-header"
                            expandIcon={<ExpandMoreIcon />}
                            TransitionProps={{ unmountOnExit: true }}
                          >
                            <Typography className={classes.accordionHeading}>
                              {i18n.t("contacts.accordion.title")}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className={classes.contactLabelsField}>
                            {/* Contact has labels */}
                            {contact.labels && contact.labels.map((label) => (
                              <span style={{ border: `2px solid ${label.color}`, padding: "7px", borderRadius: "20px"}}>
                                {label.name}
                              </span>
                            ))}

                            {/* Contact does not have labels */}
                            {((contact.labels && contact.labels.length === 0)
                            || (contact.labels === null || contact.labels === undefined)) && (
                              <Typography>
                                {i18n.t("contacts.accordion.noLabels")}
                              </Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </TableCell>
                    <TableCell align="center">{contact.number}</TableCell>
                    <TableCell align="center">{contact.email}</TableCell>

                    {!isSmallScreen && (
                      <TableCell align="center">

                        {/* ***---- View Chat ----*** */}
                        <IconButton
                          className={classes.icon}
                          size="small"
                          onClick={() => handleOpenViewChatModal(contact)}
                        >
                          <VisibilityOutlinedIcon className={classes.actionButton} />
                        </IconButton>

                        {/* ***---- Active ----*** */}
                        <IconButton
                          className={classes.icon}
                          size="small"
                          onClick={() => setConfirmOpenActiveContactModal([true, contact.id])}
                        >
                          <ReplyOutlinedIcon className={classes.actionButton} />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton avatar columns={4} />}
              </TableBody>
            </Table>
          )}



          {/* 
            **************************
            ** Card View - Contacts **
            **************************
          */}
          {contactsViewLayout === "card" && typeOfContact === "0" && (
            <div className={classes.cardOverviewContainer}>
              {contacts.length > 0 && contacts.map((contact) => (
                <div className={classes.contactSlot}>

                  {/* Profile Picture */}
                  <div className={classes.profilePicContainer}>
                    <Avatar src={contact.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact.profilePicUrl} />
                  </div>

                  {/* Info Container */}
                  <div className={classes.infoContainer}>

                    {/* Contact Info */}
                    <div className={classes.contactInfoContainer}>
                      <Typography className={classes.contactName}>
                        <b>{truncateString(contact.name, 27)}</b>
                      </Typography>
                      <Typography className={classes.contactNumber}>{contact.number}</Typography>
                      <Typography className={classes.contactEmail}>{truncateString(contact.email, 23)}</Typography>
                    </div>

                    {/* Action Buttons */}
                    <div className={classes.actionButtonsContainer}>

                      {/* ***---- Labels ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenContactTagsModal(contact.labels)}
                      >
                        <LoyaltyOutlinedIcon className={classes.actionButton} />
                      </IconButton>

                      {/* ***---- Open Private Chat ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenPrivateChatConversationModal(contact)}
                      >
                        <WhatsAppIcon className={classes.actionButton} />
                      </IconButton>                      

                      {/* ***---- View Chat ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenViewChatModal(contact)}
                      >
                        <VisibilityOutlinedIcon className={classes.actionButton} />
                      </IconButton>

                      {/* ***---- Block and Edit ----*** */}
                      {!["WhatsApp Official ✔️", "Meta AI"].includes(contact.name) && (
                        <>
                          {/* ***---- Block ----*** */}
                          <Can
                            role={user.profile}
                            perform="contacts-page:blockContact"
                            yes={() => (
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={() => handleBlockOrUnblockContact(contact.id, true)}
                              >
                                <BlockOutlinedIcon className={classes.actionButton} />
                              </IconButton>
                            )}
                          />

                          {/* ***---- Edit ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => hadleEditContact(contact)}
                          >
                            <EditIcon className={classes.actionButton} />
                          </IconButton>
                        </>
                      )}

                      {/* ***---- Delete ----*** */}
                      <Can
                        role={user.profile}
                        perform="contacts-page:deleteContact"
                        yes={() => (
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={(e) => {
                              setConfirmOpenDeleteContactModal(true);
                              setDeletingContact(contact);
                            }}
                          >
                            <DeleteOutlineIcon className={classes.actionButton} />
                          </IconButton>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {loading && <CardContactSkeleton />}
            </div>
          )}



          {/* 
            ************************
            ** Card View - Groups **
            ************************
          */}
          {contactsViewLayout === "card" && typeOfContact === "1" && (
            <div className={classes.cardOverviewContainer}>
              {contacts.length > 0 && contacts.map((contact) => (
                <div className={classes.contactSlot}>

                  {/* Profile Picture */}
                  <div className={classes.profilePicContainer}>
                    <Avatar src={contact.profilePicUrl} />
                  </div>

                  {/* Info Container */}
                  <div className={classes.infoContainer}>

                    {/* Contact Info */}
                    <div className={classes.contactInfoContainer}>
                      <Typography className={classes.contactName}>
                        <b>{truncateString(contact.name, 27)}</b>
                      </Typography>
                    </div>

                    {/* Action Buttons */}
                    <div className={classes.actionButtonsContainer}>

                      {/* ***---- Open Private Chat ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenPrivateChatConversationModal(contact)}
                      >
                        <WhatsAppIcon className={classes.actionButton} />
                      </IconButton>

                      {/* ***---- View Chat ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenViewChatModal(contact)}
                      >
                        <VisibilityOutlinedIcon className={classes.actionButton} />
                      </IconButton>

                      {/* ***---- Delete ----*** */}
                      <Can
                        role={user.profile}
                        perform="contacts-page:deleteContact"
                        yes={() => (
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={(e) => {
                              setConfirmOpenDeleteContactModal(true);
                              setDeletingContact(contact);
                            }}
                          >
                            <DeleteOutlineIcon className={classes.actionButton} />
                          </IconButton>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {loading && <CardContactSkeleton />}
            </div>
          )}



          {/* 
            **********************************
            ** Card View - Blocked Contacts **
            **********************************
          */}
          {contactsViewLayout === "card" && typeOfContact === "3" && (
            <div className={classes.cardOverviewContainer}>
              {contacts.length > 0 && contacts.map((contact) => (
                <div className={classes.contactSlot}>

                  {/* Profile Picture */}
                  <div className={classes.profilePicContainer}>
                    <Avatar src={contact.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact.profilePicUrl} />
                  </div>

                  {/* Info Container */}
                  <div className={classes.infoContainer}>

                    {/* Contact Info */}
                    <div className={classes.contactInfoContainer}>
                      <Typography className={classes.contactName}>
                        <b>{truncateString(contact.name, 27)}</b>
                      </Typography>
                      <Typography className={classes.contactNumber}>{contact.number}</Typography>
                      <Typography className={classes.contactEmail}>{truncateString(contact.email, 23)}</Typography>
                    </div>

                    {/* Action Buttons */}
                    <div className={classes.actionButtonsContainer}>

                      {/* ***---- Label ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenContactTagsModal(contact.labels)}
                      >
                        <LoyaltyOutlinedIcon className={classes.actionButton} />
                      </IconButton>

                      
                      {/* ***---- View Chat ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenViewChatModal(contact)}
                      >
                        <VisibilityOutlinedIcon className={classes.actionButton} />
                      </IconButton>

                      {/* ***---- Block and Edit ----*** */}
                      {!["WhatsApp Official ✔️", "Meta AI"].includes(contact.name) && (
                        <>
                          {/* ***---- Block ----*** */}
                          <Can
                            role={user.profile}
                            perform="contacts-page:unblockContact"
                            yes={() => (
                              <IconButton
                                className={classes.icon}
                                size="small"
                                onClick={() => handleBlockOrUnblockContact(contact.id, false)}
                              >
                                <BlockOutlinedIcon className={classes.actionButton} />
                              </IconButton>
                            )}
                          />

                          {/* ***---- Edit ----*** */}
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={() => hadleEditContact(contact)}
                          >
                            <EditIcon className={classes.actionButton} />
                          </IconButton>
                        </>
                      )}

                      {/* ***---- Delete ----*** */}
                      <Can
                        role={user.profile}
                        perform="contacts-page:deleteContact"
                        yes={() => (
                          <IconButton
                            className={classes.icon}
                            size="small"
                            onClick={(e) => {
                              setConfirmOpenDeleteContactModal(true);
                              setDeletingContact(contact);
                            }}
                          >
                            <DeleteOutlineIcon className={classes.actionButton} />
                          </IconButton>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {loading && <CardContactSkeleton />}
            </div>
          )}



          {/* 
            **************************************
            ** Card View - Inactivated Contacts **
            **************************************
          */}
          {contactsViewLayout === "card" && typeOfContact === "4" && (
            <div className={classes.cardOverviewContainer}>
              {contacts.length > 0 && contacts.map((contact) => (
                <div className={classes.contactSlot}>
                  
                  {/* Profile Picture */}
                  <div className={classes.profilePicContainer}>
                    <Avatar src={contact.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact.profilePicUrl} />
                  </div>

                  {/* Info Container */}
                  <div className={classes.infoContainer}>

                    {/* Contact Info */}
                    <div className={classes.contactInfoContainer}>
                      <Typography className={classes.contactName}>
                        <b>{truncateString(contact.name, 27)}</b>
                      </Typography>
                      <Typography className={classes.contactNumber}>{contact.number}</Typography>
                      <Typography className={classes.contactEmail}>{truncateString(contact.email, 23)}</Typography>
                    </div>

                    {/* Action Buttons */}
                    <div className={classes.actionButtonsContainer}>

                      {/* ***---- Label ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenContactTagsModal(contact.labels)}
                      >
                        <LoyaltyOutlinedIcon className={classes.actionButton} />
                      </IconButton>

                      {/* ***---- View Chat ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => handleOpenViewChatModal(contact)}
                      >
                        <VisibilityOutlinedIcon className={classes.actionButton} />
                      </IconButton>
                      
                      {/* ***---- Active ----*** */}
                      <IconButton
                        className={classes.icon}
                        size="small"
                        onClick={() => setConfirmOpenActiveContactModal([true, contact.id])}
                      >
                        <ReplyOutlinedIcon className={classes.actionButton} />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
              {loading && <CardContactSkeleton />}
            </div>
          )}
        </div>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
