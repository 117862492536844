import React, { useState, useEffect, useRef, useContext } from "react";
import { Formik, FieldArray, Form, Field } from "formik";
import { green } from "@material-ui/core/colors";
import { IMaskInput } from 'react-imask';
import { makeStyles } from "@material-ui/core/styles";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Typography
} from "@material-ui/core";
import * as Yup from "yup";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PropTypes from 'prop-types';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import LabelSelect from "../LabelSelect";
import removeCPFCNPJMask from "../../utils/removeCPFCNPJMask";
import toastError from "../../errors/toastError";
import UserSelect from "../UserSelect";
import useWhatsApps from "../../hooks/useWhatsApps";
import validateBirthday from "../../utils/validateBirthday";
import validateCpfCnpj from "../../utils/validateCpfCnpj";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
	root: { display: "flex", flexWrap: "wrap" },
	textField: { flex: 1 },
	extraAttr: { display: "flex", justifyContent: "center", alignItems: "center" },
	btnWrapper: { position: "relative" },

	dialogContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "start",
		justifyContent: "center",
		gap: "1em",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	floatingButton: { transition: 'transform 0.3s', '&:hover': { transform: 'translateY(-5px)' }, },
	actionButton: { "&:hover": { color: theme.palette.primary.main }, },

	cpfCnpjContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
	},

	fieldContainer1: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "start",
		justifyContent: "center",
		gap: "1em",
	},

	fieldContainer2: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "space-between",
		gap: "1em",
	},

	fieldContainer3: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "start",
		justifyContent: "center",
		gap: "1em",
	},
}));

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, typeCpfCnpj, ...other } = props;
	const cpfMask = "000.000.000-00";
	const cnpjMask = "00.000.000/0000-00";

  return (
    <IMaskInput
      {...other}
      mask={typeCpfCnpj ? cpfMask : cnpjMask}
      definitions={{ '#': /[1-9]/, }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
	typeCpfCnpj: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ContactModal = ({
	open, onClose, contactId, contactType, ticketWhatsappId,
	initialValues, onSave, setContactsCount
}) => {
	//	***************
	//	** Variables **
	//	***************
	const classes = useStyles();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	const [selectedLabelIds, setSelectedLabelIds] = useState([]);

	const [selectedUserIds, setSelectedUserIds] = useState([]);

	const [prioritizeUserSetting, setPrioritizeUserSetting] = useState(null);

	const [whatsappId, setWhatsappId] = useState("");
	const {isLoading: loading, whatsApps} = useWhatsApps();
	
	let ContactSchema;
	const initialState = {
		name: "",
		number: "",
		serialNumber: "",
		resaleSerialNumber: "",
		clientCode: "",
		email: "",
		isBot: false,
		sendButtonsLists: false,
		cpfCnpj: "",
		cpfCnpjType: true,
		birthday: "",
		surname: ""
	};
	const [contact, setContact] = useState(initialState);


	// 	**************
	// 	** CPF/CNPJ **
	// 	**************
	const [CPFCNPJ, setCPFCNPJ] = useState("");
	const [CPFCNPJChecked, setCPFCNPJChecked] = useState(false);
  const handleChange = (event) => { setCPFCNPJ(event.target.value); };



	//  **************
	// 	** Birthday **
	// 	**************
	const [birthday, setBirthday] = useState();
	const handleChangeBirthday = (newBirthday) => { setBirthday(newBirthday.target.value); };



	//  **************
	// 	** Settings **
	// 	**************
	const { getSetting, allUsers, allLabels, isLoading } = useGlobalRegisters();



	//	*****************
	//	** Use Effects **
	//	*****************
	useEffect(() => {
		if (isLoading) return;
		setPrioritizeUserSetting(getSetting("prioritizeUser"));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSetting, isLoading]);

	useEffect(() => {
		return () => {isMounted.current = false;};
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact(prevState => { return { ...prevState, ...initialValues }; });
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);

				if (isMounted.current) {

					// ***---- Contact Labels ----***
					const contactLabelIds = data.labels?.map(label => label.id);
					setSelectedLabelIds(
						contactLabelIds === undefined || contactLabelIds === null
						? [] : contactLabelIds
					);

					// ***---- Contact Users ----***
					const contactUserIds = data.users?.map(user => user.id);
					setSelectedUserIds(
						contactUserIds === undefined || contactUserIds === null
						? [] : contactUserIds
					);

					// ***---- Contact ----***
					setCPFCNPJ(data.cpfCnpj);
					setCPFCNPJChecked(data.cpfCnpjType);

					const birthdayValue = data.birthday && data.birthday !== "" 
						? data.birthday.substring(0, 10)
						: "";
					setBirthday(birthdayValue);

					setContact(data);
					setWhatsappId(data.whatsappId ? data.whatsappId : '');
				}
			} catch (error) {
				console.log("Contact Modal Use Effect 2 Error:", error);
				toastError(error);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);



	//	********************
	//	** Contact Schema **
	//	********************
	//
	// FX Sistemas Tenant
	if (user.tenantId.toString() === "1") {
		ContactSchema = Yup.object().shape({
			name: Yup.string()
				.min(2, "Too Short!")
				.max(512, "Too Long!")
				.required("Required"),
			number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
			serialNumber: Yup.string("Required").nullable().required("Required"),
			resaleSerialNumber: Yup.string("Required").nullable().required("Required"),
			email: Yup.string().email("Invalid email"),
		});
	} 
	
	// Tenant Normal
	else {
		ContactSchema = Yup.object().shape({
			name: Yup.string()
				.min(2, "Too Short!")
				.max(512, "Too Long!")
				.required("Required"),
			number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
			email: Yup.string().email("Invalid email")
		});
	}
	


	//	***************
	//	** Functions **
	//	***************
	const handleClose = () => {
		onClose();
		setContact(initialState);
		setSelectedUserIds([]);
		setSelectedLabelIds([]);
		setWhatsappId("");
		setCPFCNPJChecked(true);
		setCPFCNPJ("");
		setBirthday();
	};

	const handleSave = async values => {
		if (contactType === 0) await handleSaveContact(values);
		else if (contactType === 1) await handleSaveGroup(values);
	};

	const handleSaveContact = async values => {
		try {
			// ---- Labels, Default Whatsapp and Prioritized Users -----
			values.labelIds = selectedLabelIds;
			values.whatsappId = whatsappId === "" ? null : whatsappId;
			if (prioritizeUserSetting === "enabled") values.userIds = selectedUserIds;

			// ---- CPF/CNPJ Validations ----
			if (
				CPFCNPJ && CPFCNPJ !== undefined && CPFCNPJ !== "" &&
				!validateCpfCnpj(CPFCNPJ, CPFCNPJChecked)
			) {
				return;
			}

			values.cpfCnpj = CPFCNPJ && CPFCNPJ !== undefined
				? removeCPFCNPJMask(CPFCNPJ)
				:	CPFCNPJ;
			values.cpfCnpjType = CPFCNPJChecked;

			// ---- Birthday Validation ----
			if (birthday && birthday !== "" && !validateBirthday(birthday)) { return; }
			else { values.birthday = birthday; }

			// ---- API Calls ----
			if (contactId) {
				await api.put(`/contacts/${contactId}`, values);
			} else {
				const { data } = await api.post("/contacts", values);
				if (onSave) { onSave(data); }
			}

			if (setContactsCount && setContactsCount !== undefined) {
				const { data } = await api.get("/contactsCount");
				setContactsCount(data);
			}

			handleClose();
		} catch (error) {
			console.log("Contact Modal Handle Save Contact Error:", error);
			toastError(error);
		}
	};

	const handleSaveGroup = async values => {
		try {
			if (contactId) {
				await api.put(`/groups/${contactId}`, {
					name: values.name,
					extraInfo: values.extraInfo,
					number: values.number,
					whatsappId: ticketWhatsappId && ticketWhatsappId !== undefined ? ticketWhatsappId : -1
				});
			} else {
				// TO-DO: group creation
			}

			if (setContactsCount && setContactsCount !== undefined) {
				const { data } = await api.get("/contactsCount");
				setContactsCount(data);
			}

			handleClose();
		} catch (error) {
			console.log("Contact Modal handle Save Group Error:", error);
			toastError(error);
		}
	};



	//	************
	//	** Return **
	//	************
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" scroll="paper">
				{/* 
					***********
					** Title **
					***********				
				*/}
				<DialogTitle id="form-dialog-title">
					{contactId
						? `${i18n.t("contactModal.title.edit")}`
						: `${i18n.t("contactModal.title.add")}`}
				</DialogTitle>



				{/* 
					**********
					** Form **
					**********				
				*/}
				<Formik
					initialValues={contact}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {setTimeout(() => {handleSave(values); actions.setSubmitting(false);}, 400);}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent className={classes.dialogContent} dividers>
								<Typography variant="subtitle1" gutterBottom>
									{i18n.t("contactModal.form.mainInfo")}
								</Typography>

								{contactType === 0 && (
									<>
									{/* 
										***************************
										** Name and Number Field **
										***************************
									*/}
									<div className={classes.fieldContainer3} style={{ flexDirection: isSmallScreen ? "column" : "row", }}>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.name")}
											name="name"
											autoFocus
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
											className={classes.textField}
											fullWidth={isSmallScreen ? true : false}
										/>

										<Field
											as={TextField}
											label={i18n.t("contactModal.form.number")}
											name="number"
											error={touched.number && Boolean(errors.number)}
											helperText={touched.number && errors.number}
											placeholder="5599999999999"
											variant="outlined"
											margin="dense"
											fullWidth={isSmallScreen ? true : false}
										/>
									</div>

								
									{/* 
										*************
										** Surname **
										*************
									*/}
									<div className={classes.fieldContainer1}>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.surname")}
											name="surname"
											error={touched.surname && Boolean(errors.surname)}
											helperText={touched.surname && errors.surname}
											fullWidth
											margin="dense"
											variant="outlined"
										/>
									</div>


								
									{/* 
										**************
										** Birthday **
										**************
									*/}
									<div className={classes.fieldContainer1}>
										<TextField
											value={birthday}
											onChange={handleChangeBirthday}
											label={i18n.t("contactModal.form.birthday")}
											name="birthday"
											type="date"
											variant="outlined"
											margin="normal"
											InputLabelProps={{ shrink: true }}
											fullWidth
										/>
									</div>

								

									{/* 
										****************
										** CPF - CNPJ **
										****************
									*/}
									<div className={classes.fieldContainer2}>
										<FormControlLabel
											control={
												<Field
													as={Switch}
													color="primary"
													name="cpfCnpjType"
													checked={CPFCNPJChecked}
													onChange={() => setCPFCNPJChecked((prevValue) => !prevValue)}
												/>
											}
											label={CPFCNPJChecked ? "CPF" : "CNPJ"}
										/>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.cpfCnpj")}
											name="cpfcnpj"
											onChange={handleChange}
											value={CPFCNPJ}
											variant="outlined"
											margin="dense"
											className={classes.textField}
											InputProps={{
												inputComponent: TextMaskCustom,
												inputProps: { typeCpfCnpj: CPFCNPJChecked }
											}}
											InputLabelProps={{ shrink: true }}
										/>
									</div>



									{/* 
										*************************************
										** Resale Number and Serial Number **
										*************************************
									*/}
									{user.tenantId.toString() === "1" && (
										<div className={classes.fieldContainer3} style={{ flexDirection: isSmallScreen ? "column" : "row", }}>
											<Field
												as={TextField}
												label={i18n.t("contactModal.form.resaleSerialNumber")}
												name="resaleSerialNumber"
												error={touched.resaleSerialNumber && Boolean(errors.resaleSerialNumber)}
												helperText={touched.resaleSerialNumber && errors.resaleSerialNumber}
												variant="outlined"
												margin="dense"
												className={classes.textField}
												fullWidth={isSmallScreen ? true : false}
											/>
											
											<Field
												as={TextField}
												label={i18n.t("contactModal.form.serialNumber")}
												name="serialNumber"
												error={touched.serialNumber && Boolean(errors.serialNumber)}
												helperText={touched.serialNumber && errors.serialNumber}
												variant="outlined"
												margin="dense"
												fullWidth={isSmallScreen ? true : false}
											/>
										</div>
									)}



									{/* 
										*****************
										** Client Code **
										*****************
									*/}
									{(user.tenantId !== 1 && user.tenantId !== "1") && (
										<div className={classes.fieldContainer1}>
											<Field
												as={TextField}
												label={i18n.t("contactModal.form.clientCode")}
												name="clientCode"
												error={touched.clientCode && Boolean(errors.clientCode)}
												helperText={touched.clientCode && errors.clientCode}
												variant="outlined"
												margin="dense"
												fullWidth
											/>
										</div>
									)}



									{/* 
										***********
										** Email **
										***********
									*/}
									<div className={classes.fieldContainer1}>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.email")}
											name="email"
											error={touched.email && Boolean(errors.email)}
											helperText={touched.email && errors.email}
											placeholder="Email address"
											fullWidth
											margin="dense"
											variant="outlined"
										/>
									</div>



									{/* 
										************
										** Labels **
										************
									*/}
									<div className={classes.fieldContainer1}>
									<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
										<LabelSelect
											selectedLabelIds={selectedLabelIds}
											onChange={values => setSelectedLabelIds(values)}
											labelsOptions={allLabels}
											styleSelectInputField={false}
										/>
									</FormControl>



									{/* ---- Whatsapp Field ---- */}
										{!loading && (
											<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
												<InputLabel>{i18n.t("contactModal.form.whatsapp")}</InputLabel>
												<Field
													as={Select}
													value={whatsappId}
													onChange={(e) => setWhatsappId(e.target.value)}
													label={i18n.t("contactModal.form.whatsapp")}
												>
													<MenuItem value={''}>&nbsp;</MenuItem>
													{whatsApps.map((whatsapp) => (
														<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
													))}
												</Field>
											</FormControl>
										)}
									</div>
								
								

									{/*
										*********************
										** Prioritize User **
										*********************
									*/}
									{prioritizeUserSetting === "enabled" && (
										<div className={classes.fieldContainer1}>
											<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
												<UserSelect 
													selectedUserIds={selectedUserIds}
													onChange={values => setSelectedUserIds(values)}
													usersOptions={allUsers}
													styleSelectInputField={false}
												/>
											</FormControl>
											<br />
										</div>
									)}
		
								

									{/*
										***********
										** isBot **
										***********
									*/}
									<div>
										<FormControlLabel
											control={<Field as={Switch} color="primary" name="isBot" checked={values.isBot} />}
											label={i18n.t("contactModal.form.isBot")}										
										/>
									</div>
									</>
								)}

								{contactType === 1 && (
									<>
									{/* 
										***************************
										** Name and Number Field **
										***************************
									*/}
									<div className={classes.fieldContainer3} style={{ flexDirection: isSmallScreen ? "column" : "row", }}>
										<Field
											as={TextField}
											label={i18n.t("contactModal.form.name")}
											name="name"
											autoFocus
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
											className={classes.textField}
											fullWidth={isSmallScreen ? true : false}
										/>
									</div>
									</>
								)}




								{/* 
									**********************
									** Extra Info Title **
									**********************
								*/}
								<Typography style={{ marginBottom: 8, marginTop: 12 }} variant="subtitle1">
									{i18n.t("contactModal.form.extraInfo")}
								</Typography>



								{/*
									****************
									** Extra Info **
									****************
								*/}
								<FieldArray name="extraInfo">
									{({ push, remove }) => (
										<>
											{values.extraInfo &&
												values.extraInfo.length > 0 &&
												values.extraInfo.map((info, index) => (
													<div className={classes.extraAttr} key={`${index}-info`}>
														<Field
															as={TextField}
															label={i18n.t("contactModal.form.extraName")}
															name={`extraInfo[${index}].name`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<Field
															as={TextField}
															label={i18n.t("contactModal.form.extraValue")}
															name={`extraInfo[${index}].value`}
															variant="outlined"
															margin="dense"
															className={classes.textField}
														/>
														<IconButton
															size="small"
															onClick={() => remove(index)}
														>
															<DeleteOutlineIcon className={classes.actionButton} />
														</IconButton>
													</div>
												))}

											<div className={classes.fieldContainer1}>
												<Button
													style={{ flex: 1, marginTop: 8 }}
													variant="outlined"
													color="inherit"
													onClick={() => push({ name: "", value: "" })}
													className={classes.floatingButton}
												>{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}</Button>
											</div>
										</>
									)}
								</FieldArray>
							</DialogContent>



							{/* 
								********************
								** Action Buttons **
								********************
							*/}
							<DialogActions>
								<Button
									onClick={handleClose}
									color="inherit"
									disabled={isSubmitting}
									variant="outlined"
									className={classes.floatingButton}
								>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>

								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={`${classes.btnWrapper} ${classes.floatingButton}`}
								>
									{contactId ? `${i18n.t("contactModal.buttons.okEdit")}` : `${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (<CircularProgress size={24} className={classes.buttonProgress} /> )}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactModal;
