import React, { useCallback, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField
} from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
	toogleButton: {
		padding: "5px",
		display: "flex",
		alignItems: "center",
		justifyContent: "left",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: "5px",
		fontSize: "16px",
	},

	toogleSpan: { cursor: "pointer", },

	floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const filter = createFilterOptions({trim: true,});

const NewTicketModal = ({ modalOpen, onClose, setTabOpen }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const history = useHistory();
	const { whatsApps } = useWhatsApps();
	const { user } = useContext(AuthContext);
	const defaultWhatsapp = whatsApps.length === 1 ? whatsApps[0] : null;

	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const [checked, setChecked] = useState(true);
	const [userQueues, setUserQueues] = useState([]);
	const [whatsappId, setWhatsappId] = useState("");

	const { getSetting, getUserQueuesIds } = useGlobalRegisters();



	// 	***************
	// 	** Callbacks **
	// 	***************
	const setDefaultWhatsappCallback = useCallback(() => {
		if (user.whatsapp?.id) setWhatsappId(user.whatsapp.id);
		else setWhatsappId(whatsappId === "" && defaultWhatsapp ? defaultWhatsapp.id : whatsappId);
	}, [defaultWhatsapp, whatsappId, user]);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			setDefaultWhatsappCallback();
			return;
		}
		
		setLoading(true);

		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam, typeOfContact: "0" },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (error) {
					setLoading(false);
					console.log("New Ticket Modal Use Effect 1 Error:", error);
					toastError(error);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen, setDefaultWhatsappCallback]);

	useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUserQueuesIds = async () => {
        try {
					const userQueuesIds = await getUserQueuesIds(user.id);
          setUserQueues(userQueuesIds);
          setLoading(false);
        } catch (error) {
          setLoading(false);
					console.log("New Ticket Modal Use Effect 2 Error:", error);
          toastError(error);
        }
      };
      fetchUserQueuesIds();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [getUserQueuesIds, user.id]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleChange = () => {
		setChecked(checked ? false : true);
  };

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
		setWhatsappId("");
	};

	const handleSaveTicket = async (contactId) => {
		if (!contactId) return;
		setLoading(true);

		try {
			if (!whatsappId || whatsappId === undefined || whatsappId === "") {
				toast.info(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
			}

			else {
				let params = {
					contactId: contactId,
					userId: checked ? user.id : null,
					status: checked ? "open" : "pending",
					isCreatedByUser: true,
					isBotInteractionFinished: true,
					whatsappId
				};
				
				const apiCalls = [api.post("/tickets", params)];
				const [TicketsResponse] = await Promise.all(apiCalls);

				const adminCanViewOtherChats = getSetting("adminCanViewOtherChats");
				const ticket = TicketsResponse.data;
				const pendingTicketCondition = ticket.status === "pending" && (ticket.queueId === null || userQueues.includes(ticket.queueId));
				const openTicketAndAdminUserCondition = ticket.status === "open" && user.profile === "admin" && adminCanViewOtherChats === "enabled";
				const openTicketAndCommonUserCondition = ticket.status === "open" && ticket.userId === user.id;


				// *** User can see Ticket ***
				if (pendingTicketCondition || openTicketAndAdminUserCondition || openTicketAndCommonUserCondition) {
					setTabOpen(ticket.status);
					localStorage.setItem("tabOpenTickets", ticket.status);
					setChecked(true);
					history.push(`/tickets/${ticket.id}`);
				}

				// *** User cannot see Ticket ***
				else {

					// *** Pending Ticket in another Queue ***
					if (ticket.status === "pending") {
						toast.info(`
							${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
							${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_QUEUE")}
							${ticket.queue?.name}
						`);
					}
					
					// *** Ticket with another User ***
					else {
						toast.info(`
							${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
							${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_USER")}
							${ticket.user?.name}
						`);
					}
				}
				handleClose();
			}
		} catch (error) {
			console.log("Handle Save Ticket Error:", error);
			toastError(error);
		}

		setLoading(false);
	};

	const handleSelectOption = (newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
		} else if (newValue?.name) {
			setNewContact({ name: newValue.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleAddNewContactTicket = (contact) => {
		handleSaveTicket(contact.id);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = (option) => {
		if (option.number) {
			return `${option.name} - ${option.number}`;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option.name}`;
		}
	};

	const renderOptionLabel = (option) => {
		if (option.number) return `${option.name} - ${option.number}`;
		else return `${option.name}`;
	};



	// 	************
	// 	** Return **
	// 	************
	return (
		<>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
				contactType={0}
				contactId={null}
			/>

			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("newTicketModal.title")}
				</DialogTitle>

				<DialogContent dividers>
					<Autocomplete
						options={options}
						loading={loading}
						style={{ width: 300 }}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								onKeyDown={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") { handleSaveTicket(selectedContact.id); }
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>

					<br />

					<FormControl variant="outlined" margin="dense" fullWidth > 
						<InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
						<Select
							value={whatsappId}
							onChange={(event) => setWhatsappId(event.target.value)}
							label={i18n.t("newTicketModal.connection")}
							fullWidth
						>
							<MenuItem value={""}>&nbsp;</MenuItem>
							{whatsApps.map((whatsapp) => (
								<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
							))}
						</Select>
					</FormControl>
					
					<div className={classes.toogleButton}>
						<Switch id="containerToggle" checked={checked} onChange={handleChange} />
						<span className={classes.toogleSpan} onClick={handleChange}>
							{i18n.t("newTicketModal.buttons.toogle")}
						</span>
					</div>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={handleClose}
						color="inherit"
						disabled={loading}
						variant="outlined"
						className={classes.floatingButton}
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>

					<ButtonWithSpinner
						className={classes.floatingButton}
						variant="contained"
						type="button"
						disabled={!selectedContact}
						onClick={() => handleSaveTicket(selectedContact.id)}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
