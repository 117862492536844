import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
	chips: { display: "flex", flexWrap: "wrap" },

	chip: { margin: 2, color: theme.palette.text.primary },

	chipCheckbox: {
		color: theme.palette.primary.main,
		'&.Mui-checked': { color: theme.palette.primary.main },
	},
}));

const QueueSelectModals = ({ selectedQueueIds, onChange }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const { allQueues } = useGlobalRegisters();



	// 	**************
	// 	** Function **
	// 	**************
	const handleChange = event => { onChange(event.target.value); };



	// 	************
	// 	** Return **
	// 	************
	return (
		<div style={{
			width: "100%",
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			alignItems: "start",
			justifyContent: "center",
		}}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>{i18n.t("queueSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					label={i18n.t("queueSelect.inputLabel")}
					value={selectedQueueIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: { vertical: "bottom", horizontal: "left" },
						transformOrigin: { vertical: "top", horizontal: "left" },
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const queue = allQueues.find(q => q.id === id);
									return queue
										? (<Chip key={id} style={{ border: `2px solid ${queue.color}` }} variant="outlined" label={queue.name} className={classes.chip} />)
										: null;
								})}
						</div>
					)}
				>
					{allQueues.map(queue => (
						<MenuItem key={queue.id} value={queue.id}>					
							<Checkbox className={classes.chipCheckbox} checked={selectedQueueIds.indexOf(queue.id) > -1} />
              <ListItemText primary={queue.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default QueueSelectModals;
