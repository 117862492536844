import React, { useContext, useEffect, useReducer, useState } from "react";
import { 
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery
} from "@material-ui/core";
import { DeleteOutline, FormatListBulletedOutlined, GetApp, RotateLeftOutlined } from "@material-ui/icons";
import clsx from "clsx";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import AdsStarredMessage from "../../components/AdsStarredMessage";
import api from "../../services/api";
import Audio from "../../components/Audio";
import ConfirmationModal from "../../components/ConfirmationModal";
import EventPreview from "../../components/EventPreview";
import GroupInvitePreview from "../../components/GroupInvitePreview";
import LocationPreview from "../../components/LocationPreview";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MessageImage from "../../components/MessageImage";
import MultiVcardPreview from "../../components/MultiVcardPreview";
import PollPreview from "../../components/PollPreview";
import PollVotesModal from "../../components/PollVotesModal";
import SocketStarredMessages from "../../context/Socket/Listeners/SocketStarredMesssages";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import UserSelect from "../../components/UserSelect";
import VcardPreview from "../../components/VcardPreview";

import { Videoplayer } from "@openabos/react-circular-player";
import "@openabos/react-circular-player/dist/cjs/index.css";
import "../../styles/react-circular-player-new-styles.css";

import saveFile from "../../utils/saveFile";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_STARRED_MESSAGES") {
    const starredMessages = action.payload;
    const newStarredMessages = [];

    starredMessages.forEach((starredMessage) => {
      const starredMessageIndex = state.findIndex((s) => s.id === starredMessage.id);
      if (starredMessageIndex !== -1) { state[starredMessageIndex] = starredMessage; }
      else { newStarredMessages.push(starredMessage); }
    });

    return [...state, ...newStarredMessages];
  }

  if (action.type === "DELETE_STARRED_MESSAGES") {
    const starredMessageId = action.payload;
    const starredMessageIndex = state.findIndex((s) => s.id === starredMessageId);
    if (starredMessageIndex !== -1) { state.splice(starredMessageIndex, 1); }
    return [...state];
  }

  if (action.type === "RESET") { return []; }
};

const useStyles = makeStyles((theme) => ({
  //  ********************
  //  ** Main Container **
  //  ********************
  mainPaper: {
    flex: 1,
    padding: "1em",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },



  //  **********************
  //  ** Filter Container **
  //  **********************
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "1em",
  },

  sectionContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		flexWrap: "wrap",

		[theme.breakpoints.down('sm')]: {
      justifyContent: "center",
			gap: "10px",
    },
	},

  filterField: { width: "250px" },



  //  ************************
  //  ** Messages Container **
  //  ************************
  messagesContainer: {
    
  },

  icon: {
		color: theme.palette.text.primary
	},

  actionButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },

  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },

  vcardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "1em",
  },

  vcardView: {
    maxWidth: "250px",
    backgroundColor: theme.palette.primary.messageBox,
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  messageMediaFullScreen: {
    transition: "all 0.30s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },  

  stickerMessage: {
    maxHeight: 225,
    maxWidth: 225,
    borderRadius: 5,
    transition: "all 0.3s",
  
    [theme.breakpoints.down("sm")]: {
      maxHeight: 150,
      maxWidth: 150,
    },
  },

  deletedStickerMessage: {
    opacity: 0.7,
    filter: "grayscale(80%)",
  },

  downloadMedia: {
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  loadMoreContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1em",
  },

  adsStarredMessageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "3px",
  },
}));


const StarredMessages = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [starredMessages, dispatch] = useReducer(reducer, []);

  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const [pollVotesModalOpen, setPollVotesModalOpen] = useState(false);
  const [selectedPollVotesMessage, setSelectedPollVotesMessage] = useState([]);

  const { allUsers } = useGlobalRegisters();



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [selectedUserIds]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/starredMessagesList", {
            params: { selectedUserIds, pageNumber },
          });
          dispatch({ type: "LOAD_STARRED_MESSAGES", payload: data.starredMessages });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (error) {
          console.log("Starred Messages Use Effect 1 Error:", error);
          toastError(error);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [selectedUserIds, pageNumber]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", () => {
      setIsFullscreen(!!document.fullscreenElement);
    });
  
    return () => {
      document.removeEventListener("fullscreenchange", setIsFullscreen(!!document.fullscreenElement));
    };
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenPollVotesModal = (message) => {
    setSelectedPollVotesMessage(message);
    setPollVotesModalOpen(true);
  };

  const handleClosePollVotesModal = () => { 
    setPollVotesModalOpen(false);
    setSelectedPollVotesMessage([]);
  };

  const checkMessageMedia = (message, isQuotedMessage=false) => {
    if (message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    
    else if (message.mediaType === "button") { 
      const buttonMessage = message.body;
      const buttonMessageTitleFooter = buttonMessage.split("##");
      const buttonMessageArray = buttonMessageTitleFooter[0].split("||");
      const buttonTitle = buttonMessageTitleFooter[1];
      const buttonFooter = buttonMessageTitleFooter[2];

      const buttonBody = buttonMessageArray[0];
      const buttonOptionsArray = buttonMessageArray.slice(1);

      return(
        <>
          <b>{buttonTitle}</b>
          <p>{buttonBody}</p>
          <i className={classes.buttonListFooter}>{buttonFooter}</i>
          <hr />
          <center>
            {buttonOptionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                disabled
              >
                {option}
              </Button>
            ))}
          </center>
        </>
      );

    }
    
    else if (message.mediaType === "list") { 
      const listMessage = message.body;
      const listMessageArray = listMessage.split("||");

      const headerArray = listMessageArray[0].split("%%");
      const optionsArray = listMessageArray.slice(1);
      return (
        <>
          <b>{headerArray[0]}</b>
          <p>{headerArray[1]}</p>
          <i className={classes.buttonListFooter}>{headerArray[3]}</i>
          <center>
            <Button
              startIcon={<FormatListBulletedOutlined />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
              disabled
            >
              {headerArray[2]}
            </Button>
          </center>
          
          <hr />

          <center>
            {optionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                href={message.mediaUrl}
                disabled
              >
                {option.split("%%")[0]}
              </Button>
            ))}
          </center>
        </>
      );
    }
    
    else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("waid=") !== -1) {
            obj.push({ number: values[ind].split("waid=")[1] });
          }

          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return (
        <div className={classes.vcardContainer}>
          <div className={classes.vcardView}>
            <VcardPreview contact={contact} numbers={obj[0]?.number} enableHandleNewChat={true} />
          </div>
        </div>
      );
    }
    
    else if (message.mediaType === "multi_vcard") {
      const vcardsLimiter = "\n----vcardLimiter----\n";
      const vcards = message.body.split(vcardsLimiter).filter(vcard => vcard !== "");
      const vcardsElements = [];
      const contactsList = [];

      for (let vcardIndex = 0; vcardIndex < vcards.length; vcardIndex += 1) {
        vcardsElements.push(vcards[vcardIndex].split("\n"));
      }

      for (let vcardElementIndex = 0; vcardElementIndex < vcardsElements.length; vcardElementIndex += 1) {
        try {
          contactsList.push({
            number: vcardsElements[vcardElementIndex][4].split("waid=")[1].split(":")[0],
            name: vcardsElements[vcardElementIndex][3].split(":")[1],
          })
        } catch (err) {
          try {
            contactsList.push({
              number: vcardsElements[vcardElementIndex][6].split("waid=")[1].split(":")[0],
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            })
          } catch (err) {
            contactsList.push({
              number: undefined,
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            });
          }
        }
      }

      return (
        <div className={classes.vcardContainer}>
          <div className={classes.vcardView}>
            <MultiVcardPreview contacts={contactsList} enableStartChat={true} />
          </div>
        </div>
      );
    }
    
    else if (message.mediaType === "image") {
      return <MessageImage message={message} ticketId={message.ticketId} replyMessage={true} />;
    }
    
    else if (message.mediaType === "sticker") {
      return (
        <img
          className={clsx(classes.stickerMessage, {[classes.deletedStickerMessage]: message.isDeleted,})}
          src={message.mediaUrl} alt={message.body} width={225} height={225}
        />
      );
    }
    
    else if (message.mediaType === "audio") {
      return (
        <Audio
          transcript={message.transcript}
          url={message.mediaUrl}
          ticket={message.ticket}
          isFromStarredMessages={true}
          isVoice={message.isVoice}
          isFromMe={message.fromMe}
        />
      );
    }
    
    else if (message.mediaType === "video") {
      return (
        <>
        <video
          className={
            message.isDeleted
              ? isFullscreen
                ? `${classes.messageMediaFullScreen} ${classes.deletedMediaMessage}`
                : `${classes.messageMedia} ${classes.deletedMediaMessage}`
              : isFullscreen
                ? classes.messageMediaFullScreen
                : classes.messageMedia
          }
          src={message.mediaUrl}
          controls={!message.isGif}
          autoPlay={message.isGif}
          muted={message.isGif}
          loop={message.isGif}
        />
        </>
      );
    }
    
    else if (message.mediaType === "ptv") {
      return (
        <Videoplayer size={200} src={message.mediaUrl} boundaryColor={"#ff6961"} />
      );
    }
    
    else if (message.mediaType === "poll_creation") {
      return (
        <div className={classes.vcardContainer}>
          <div className={classes.vcardView} style={{padding: "5px"}}>
            <PollPreview message={message} handleOpenPollVotesModal={handleOpenPollVotesModal} readonly={true} />
          </div>
        </div>
      )
    }

    else if (message.mediaType === "event") {
      return <EventPreview message={message} />;
    }
    
    else if (message.mediaType === "group_invite") {
      return <GroupInvitePreview message={message} isQuotedMessage={isQuotedMessage} />
    }
    
    else {
      const mediaExtension = message.mediaUrl.split(".")[message.mediaUrl.split(".").length - 1];
      const handleClick = (event) => {
        event.preventDefault();
        saveFile(message);
      }

      return (
        <>
          <div className={classes.downloadMedia}>
            {!isSmallScreen && !isQuotedMessage && mediaExtension === "pdf" && (
              <embed src={message.mediaUrl} width="500" height="375" type="application/pdf" />
            )}

            {!isSmallScreen && !isQuotedMessage && mediaExtension === "txt" && (
              <embed src={message.mediaUrl} width="500" height="375" type="text/plain"/>
            )}

            {!isSmallScreen && !isQuotedMessage && mediaExtension === "md" && (
              <embed src={message.mediaUrl} width="500" height="375" type="text/markdown"/>
            )}

            {message.body}

            <Button
              startIcon={<GetApp />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href="#"
              onClick={handleClick}
              className={classes.floatingButton}
            >{i18n.t("messagesInput.download")}</Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const checkMessageBody = (message) => {
    return (
      <div className={classes.adsStarredMessageContainer}>
        {(message.adsSourceUrl || message.adsTitle || message.adsThumbnailUrl)
          && <AdsStarredMessage message={message} redirectFlag={true} />}
        <span>{message.body}</span>
      </div>
    );
  };

  const handleOpenConfirmModal = (messageId) => {
    setSelectedMessage(messageId);
    setConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setSelectedMessage(null);
    setConfirmModalOpen(false);
  };

  const handleUnstarMessage = async () => {
    try {
      await api.delete(`/starredMessages/${selectedMessage}`);
    } catch (error) {
      console.log("Handle Unstar Message Error:", error);
      toastError(error);
    } finally {
      setConfirmModalOpen(false);
      setSelectedMessage(null);
      setSelectedUserIds([]);
      setPageNumber(1);
    }
  };

  const loadMore = () => { setPageNumber((prevState) => prevState + 1); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      <SocketStarredMessages dispatch={dispatch} />
      
      <ConfirmationModal
        title={i18n.t("starredMessages.confirmationModal.title")}
        open={confirmModalOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={handleUnstarMessage}
      />

      <PollVotesModal
        open={pollVotesModalOpen}
        onClose={handleClosePollVotesModal}
        message={selectedPollVotesMessage}
      />

      <MainHeader>
        <Title>{i18n.t("starredMessages.title")}</Title>
      </MainHeader>

      <Paper className={classes.mainPaper} variant="outlined">
        {user.profile === "admin" && (
          <>
          <Grid item xs={12} className={classes.sectionContainer}>
            <Grid className={classes.filterField}>
              <UserSelect 
                selectedUserIds={selectedUserIds}
                onChange={values => setSelectedUserIds(values)}
                usersOptions={allUsers}
                styleSelectInputField={true}
                style={{minWidth: "250px"}}
              />
            </Grid>
          </Grid>

          <br />
          </>
        )}

        <div className={classes.messagesContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{i18n.t("starredMessages.table.actions")}</TableCell>
                <TableCell align="center">{i18n.t("starredMessages.table.user")}</TableCell>
                <TableCell align="center">{i18n.t("starredMessages.table.message")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {starredMessages.map((starredMessage) => (
                user.profile === "user" && starredMessage.isRating
                  ? (<></>)
                  : (
                    <TableRow key={starredMessage.id}>
                      <TableCell align="center">
                        <IconButton className={classes.icon} size="small" onClick={() => handleOpenConfirmModal(starredMessage.id)}>
                          <DeleteOutline className={`${classes.actionButton} ${classes.floatingButton}`} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">{
                        starredMessage.starredByUser?.name
                          ? starredMessage.starredByUser.name
                          : "-"
                      }</TableCell>
                      <TableCell align="center">
                        {starredMessage.mediaType === "chat" ? checkMessageBody(starredMessage) : checkMessageMedia(starredMessage, false)}
                      </TableCell>
                    </TableRow>
                  )
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </TableBody>
          </Table>
          
          {hasMore && (
            <div className={classes.loadMoreContainer}>
              <Button
                onClick={loadMore}
                color="inherit"
                variant="outlined"
                className={classes.floatingButton}
              >
                <RotateLeftOutlined />{i18n.t("starredMessages.loadMoreButton")}
              </Button>
            </div>
          )}
        </div>
      </Paper>
    </MainContainer>
  );
};

export default StarredMessages;