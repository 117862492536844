import React, { useContext, useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import {
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import EditClosingTicketModal from "../EditClosingTicketModal";
import toastError from "../../errors/toastError";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
	mainButton: {
		backgroundColor: theme.palette.primary.main,
    color: "#f6f6ff",
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow:"inset 0 0 0 99999px rgba(0, 0, 0, 0.2)",
   },
	},

  popper: { zIndex: "9999", },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const SplitButton = ({ ticket }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const isMounted = useRef(true);  
  const anchorRef = useRef(null);
  const { user } = useContext(AuthContext);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [editClosingTicketModalOpen, setEditClosingTicketModalOpen] = useState(false);
  
  const [whatsappQueues, setWhatsappQueues] = useState(0);

  const [allowResolveTicketWithoutGoodBye, setAllowResolveTicketWithoutGoodBye] = useState("enabled");
  const [requireTagOnContactWhenClosingTicket, setRequireTagOnContactWhenClosingTicket] = useState("disabled");

  const { getSetting, isLoading, getWhatsappQueues } = useGlobalRegisters();



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (isLoading) return;

    setAllowResolveTicketWithoutGoodBye(getSetting("allowResolveTicketWithoutGoodBye"));
    setRequireTagOnContactWhenClosingTicket(getSetting("requireTagOnContactWhenClosingTicket"));
  }, [getSetting, isLoading]);

  useEffect(() => {
		const loadWhatsappQueues = async() => {
			const list = await getWhatsappQueues(ticket.whatsappId);
			setWhatsappQueues(list);
		}

		loadWhatsappQueues();
	}, [getWhatsappQueues, ticket.whatsappId]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => { setOpen((prevOpen) => !prevOpen); };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  const handleOpenEditTicketModal = () => { setEditClosingTicketModalOpen(true); }

  const handleCloseEditClosingTicketModal = () => {
		if (isMounted.current) setEditClosingTicketModalOpen(false);
	};

  const handleUpdateTicketStatus = async (status, userId, selectedIndex) => { 
    // ***---- WhatsApp Queues not Loaded ----***
    //
    // - if whatsappQueues is not loaded yet, a toast message is shown to the user
    // informing they to wait 10 seconds before closing a ticket
    //
    if (whatsappQueues === 0) {
      toast.info(i18n.t("messagesList.toast.closeTicket.whatsappQueuesNotLoaded"));
      return; 
    }


    // ***---- Variables ----***
    let subqueuesCount = 0;
    const fxSistemasTenantId = user.tenantId.toString() === "1";
		const ticketType = ticket.type.toString() === "0";
    const isBotChat = ticket.type.toString() === "2";
    const isInternalChat = ticket.type.toString() === "3";

    // ***---- Queued Id Assigned ----***
    //
    // - if queue id is already assigned to the ticket, this one is closed
    //
    if (ticket.queueId !== null) {
      subqueuesCount = await api.get(`/subqueueCount/${ticket.queueId}`);
      subqueuesCount = subqueuesCount.data;
    }

    // ***---- Open Edit Ticket Modal ----***
    //
    // - if ticket is not type 2 (Bot)
    // - AND ticket is not type 3 (Internal Chat)
    // - AND (queue id is not assigned to the ticket and connection contains related queues
    // - OR subqueue id is not assigned and there are subqueues related to the assigned queue id
    // - OR ticket is type 0, tags are required on contact and contact doesn't contain binded tags
    // - OR (tenant is 1 (FX Systems) and ticket type is not 2 AND contact does not have serial number and resale serial number))
    //
    const isQueueRequired = !ticket.queueId && whatsappQueues.length > 0;
    const isSubqueueRequired = !ticket.subqueueId && subqueuesCount > 0;
    const isTagRequired = ticket.type === 0 && requireTagOnContactWhenClosingTicket === "enabled" && ticket.contact?.labels?.length <= 0;
    const isTenantFXCondition = fxSistemasTenantId && ticketType;
    const isSerialNumberOrResaleSerialNumberEmpty = !ticket.contact?.serialNumber || !ticket.contact?.resaleSerialNumber;
    
    const shouldOpenEditTicketModal = !isBotChat && !isInternalChat
      && (isQueueRequired || isSubqueueRequired || isTagRequired
      || (isTenantFXCondition && isSerialNumberOrResaleSerialNumberEmpty)
    );

    if (shouldOpenEditTicketModal) handleOpenEditTicketModal();

    // *** ---- Queue Id Not Required ----***
    //
    // - ticket is closed
    //
    else {
      try {
        await api.put(`/tickets/${ticket.id}/0`, {
          status: status,
          userId: ticket.type === 3 ? ticket.userId : userId || null,
          queueId: ticket.queueId,
          shouldSendAutomaticMessages: selectedIndex === 0
        });

        if (status === "open") { history.push(`/tickets/${ticket.id}`); } 
        else { history.push("/tickets"); }
      } catch (error) {
        console.log("Handle Update Ticket Status Error:", error);
        toastError(error);
      }
    }
	};

  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      {ticket.type === 0 && allowResolveTicketWithoutGoodBye === "enabled" && (
        <>
          <ButtonGroup 
            size="small"
            variant="contained"
            ref={anchorRef}
            aria-label="split button"
            className={classes.floatingButton}
          >
            <Button 
              className={classes.mainButton} 
              onClick={() => handleUpdateTicketStatus("closed", user?.id, selectedIndex)}
            >
              {selectedIndex === 0 && i18n.t("messagesList.header.buttons.resolve")}
              {selectedIndex === 1 && i18n.t("messagesList.header.buttons.resolveWithoutFarewell")}
            </Button>

            <Button
              className={classes.mainButton}
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>

          <Popper
            className={classes.popper}
            sx={{ zIndex: 1 }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal={!isSmallScreen}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem>
                      <MenuItem
                        key={i18n.t("messagesList.header.buttons.resolve")}
                        onClick={() => handleMenuItemClick(0)}
                      >
                        {i18n.t("messagesList.header.buttons.resolve")}
                      </MenuItem>
                      
                      <MenuItem
                        key={i18n.t("messagesList.header.buttons.resolveWithoutFarewell")}
                        onClick={() => handleMenuItemClick(1)}
                      >
                        {i18n.t("messagesList.header.buttons.resolveWithoutFarewell")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}

      {(ticket.type !== 0 || allowResolveTicketWithoutGoodBye === "disabled") && (
        <Button 
          className={`${classes.mainButton} ${classes.floatingButton}`} 
          onClick={() => handleUpdateTicketStatus("closed", user?.id, selectedIndex)}
        >
          {i18n.t("messagesList.header.buttons.resolve")}
        </Button>
      )}

      <EditClosingTicketModal
				modalOpen={editClosingTicketModalOpen}
				onClose={handleCloseEditClosingTicketModal}
				ticket={ticket}
        selectedIndex={selectedIndex}
        requireTagOnContactWhenClosingTicket={requireTagOnContactWhenClosingTicket}
			/>
    </>
  );
}

export default SplitButton;