import React from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
  fieldWithPersonalizedIcon: { "& .MuiSvgIcon-root": { color: theme.palette.text.primary }, },
  chips: { display: "flex", flexWrap: "wrap" },
  chip: { margin: 2, color: theme.palette.text.primary },
  chipCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': { color: theme.palette.primary.main },
  },
}));

const WhatsappSelectModals = ({ selectedWhatsappIds, onChange }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const { allWhatsapps } = useGlobalRegisters();



  //  ***************
  //  ** Functions **
  //  ***************
  const handleChange = event => { onChange(event.target.value); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "start",
      justifyContent: "center",
    }}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>{i18n.t("whatsappSelect.inputLabel")}</InputLabel>
        
        <Select
          multiple
          className={classes.fieldWithPersonalizedIcon}
          label={i18n.t("whatsappSelect.inputLabel")}
          value={selectedWhatsappIds}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected?.length > 0 &&
                selected.map(id => {
                  const whatsapp = allWhatsapps.find(w => w.id === id);
                  return whatsapp
                    ? (<Chip key={id} style={{ border: "2px solid red" }} variant="outlined" label={whatsapp.name} className={classes.chip} />)
                    : null;
                })}
            </div>
          )}
        >
          {allWhatsapps.map(whatsapp => (
            <MenuItem key={whatsapp.id} value={whatsapp.id}>
              <Checkbox className={classes.chipCheckbox} checked={selectedWhatsappIds.indexOf(whatsapp.id) > -1} />
              <ListItemText primary={whatsapp.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default WhatsappSelectModals;
