import { useEffect, useReducer } from "react";

import { useSocket } from "../../context/Socket/SocketContext";
import api from "../../services/api";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
		const queues = action.payload;
		return [...queues];
	}


	if (action.type === "UPDATE_QUEUES") {
		const queue = action.payload;
		const queueIndex = state.findIndex(q => q.id === queue.id);

		if (queueIndex !== -1) {
			state[queueIndex] = queue;
			return [...state];
		} else {
			return [queue, ...state];
		}
	}

	if (action.type === "DELETE_QUEUE") {
		const queueId = action.payload;

		const queueIndex = state.findIndex(q => q.id === queueId);
		if (queueIndex !== -1) {
			state.splice(queueIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useQueuesAll = () => {
  const [queues, dispatch] = useReducer(reducer, []);
  
  const { getSocket } = useSocket();

  const { allQueues, isLoading } = useGlobalRegisters();

  useEffect(() => {
    if (isLoading) return;
    dispatch({ type: "LOAD_QUEUES", payload: allQueues });
  }, [isLoading, allQueues]);  

  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleUpdateQueues = (data) => {
        if (data.action === "update" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "UPDATE_QUEUES", payload: data.queues });
        }
      };
    
      const handleDeleteQueues = (data) => {
        if (data.action === "delete" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "DELETE_QUEUE", payload: data.queuesId });
        }
      };
    
      socket.on("queues", handleUpdateQueues);
      socket.on("queues", handleDeleteQueues);
    
      return () => {
        socket.off("queues", handleUpdateQueues);
        socket.off("queues", handleDeleteQueues);
      };
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { queues };
};

export default useQueuesAll;