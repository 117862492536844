import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const useStyles = makeStyles(theme => ({
  chips: { display: "flex", flexWrap: "wrap" },

  chip: {
    margin: 2,
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary,
  },

  chipCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': { color: theme.palette.primary.main },
  },

  selectInputField: {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary, },
    "& .MuiSvgIcon-root": { color: theme.palette.text.primary, },
  },
}));

const WhatsappSelect = ({ selectedWhatsappIds, onChange, whatsappsOptions, styleSelectInputField=true }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const { allWhatsapps } = useGlobalRegisters();




  //  ***************
  //  ** Functions **
  //  ***************
  const handleChange = e => { onChange(e.target.value); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div>
      <FormControl
        fullWidth
        margin="dense"
        variant="outlined"
        classes={styleSelectInputField ? { root: classes.selectInputField } : { }}
      >
        <InputLabel>{i18n.t("whatsappSelect.inputLabel")}</InputLabel>
        <Select
          multiple
          label={i18n.t("whatsappSelect.inputLabel")}
          value={selectedWhatsappIds}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected?.length > 0 &&
                selected.map(id => {
                  const whatsapp = allWhatsapps.find(w => w.id === id);
                  return whatsapp
                    ? (<Chip key={id} variant="outlined" label={whatsapp.name} className={classes.chip} />)
                    : null;
                })}
            </div>
          )}
        >
          {whatsappsOptions.map(whatsapp => (
            <MenuItem key={whatsapp.id} value={whatsapp.id}>
              <Checkbox className={classes.chipCheckbox} checked={selectedWhatsappIds.indexOf(whatsapp.id) > -1} />
              <ListItemText primary={whatsapp.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default WhatsappSelect;