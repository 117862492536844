import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import openSocket from "socket.io-client";

import { AuthContext } from "../Auth/AuthContext";
import { getBackendUrl } from "../../config";
import api from "../../services/api";

import SocketSettings from "./Listeners/SocketSettings";

import { useGlobalRegisters } from "../GlobalRegisters/GlobalRegistersContext";

//  **************
//  ** Contexts **
//  **************
const SocketContext = createContext();
const SocketAuthenticatedContext = createContext();
const SocketLoginContext = createContext();

const useSocket = () => useContext(SocketContext);
const useSocketAuthenticated = () => useContext(SocketAuthenticatedContext);
const useSocketLogin = () => useContext(SocketLoginContext);



//  ***************
//  ** Providers **
//  ***************
const SocketProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const handleLogout = async () => {
		try {      
			api.defaults.headers.Authorization = undefined;
			api.defaults.headers.tenantId = undefined;
			api.defaults.headers.groupId = undefined;

      localStorage.removeItem("token");
			localStorage.setItem("selectedTicketContactId", "-1");
      window.location.reload();
		} catch (exception) {
			console.log("Handle Logout Exception:", exception);
		}
	};

  let socket;


  //  ***************
  //  ** Functions **
  //  ***************
  const connectSocket = (groupId) => {
    const config = groupId > 0 ? { path: `/group${groupId}/socket.io` } : {};
    config.transports = ["websocket"];
    config.reconnection = true;
    socket = openSocket(getBackendUrl(), config);
  };


  const getSocket = () => {
    try {
      if (socket) return socket;
  
      const token = localStorage.getItem("token");

      let groupId
      try {
        groupId = token ? JSON.parse(atob(token.split(".")[1])).groupId : undefined;
      }
      catch (exception) {        
        groupId = undefined
        console.log("- Token GetSocket Exception:", exception);
      }
  
      if (groupId || ["localhost", "127.0.0.1"].includes(window.location.hostname)) {
        connectSocket(groupId);
        return socket;
      }
    } catch (exception) {
    //  handleLogout();
    throw exception
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <SocketContext.Provider value={{ socket, connectSocket, getSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

const SocketAuthenticatedProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocket();
  const { user } = useContext(AuthContext);
  const [onlineUsers, setOnlineUsers] = useState([]);

  const reloadTimeout = useRef(null);
  const reloadTimeoutLimit = 8 * 60 * 1000; // 8 minutes in milliseconds

  const {
    setAllSettings, updateAllQuickAnswers, updateAllQueues,
    updateAllSubqueues, updateAllCategories, updateAllLabels,
    updateAllWhatsapps, setIsAuthorized
  } = useGlobalRegisters();
  
  
  
  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (Object.keys(user).length === 0) return;

    setIsAuthorized(true);

    const socket = getSocket();

    if (socket) {
      // ***---- Functions ----***
      const connectListener = () => {
        if (socket.recoverd) console.log("- Socket Connection Recovered!!");
        else console.log("- Socket Connection Established!!");
        
        clearTimeout(reloadTimeout.current);
        reloadTimeout.current = null;

        if (user.tenantId) socket.emit("joinTenant", user.tenantId.toString());
      };

      const errorListener = (err) => {
        console.log("- Socket Connect Error:", err.message, err.description, err.context);
  
        if (!reloadTimeout.current) {
          reloadTimeout.current = setTimeout(() => window.location.reload(), reloadTimeoutLimit);
        }
      }
  
      const disconnectListener = (Reason) => {
        console.log(`- Socket Disconnected: ${Reason}!!`);
      }

      // ***---- On Listeners ----***
      socket.on("connect", connectListener);
      socket.on("disconnect", disconnectListener)
      socket.on("connect_error", errorListener);

      if (user.tenantId && socket) socket.emit("joinTenant", user.tenantId.toString());

      // ***---- Global Variables ----***
      const handleQuickAnswer = (data) => updateAllQuickAnswers();
      const handleQueue = (data) => updateAllQueues();
      const handleSubqueue = (data) => updateAllSubqueues();
      const handleCategory = (data) => updateAllCategories();
      const handleLabel = (data) => updateAllLabels();
      const handleWhatsapp = (data) => updateAllWhatsapps();

      socket.on("quickAnswer", handleQuickAnswer);
      socket.on("queue", handleQueue);
      socket.on("subqueue", handleSubqueue);
      socket.on("category", handleCategory);
      socket.on("label", handleLabel);
      socket.on("whatsapp", handleWhatsapp);

      // ***---- Off Listeners ----***
      return () => {
        socket.off("connect", connectListener);
        socket.off("disconnect", disconnectListener)
        socket.off("connect_error", errorListener);

        socket.off("quickAnswer", handleQuickAnswer);
        socket.off("queue", handleQueue);
        socket.off("subqueue", handleSubqueue);
        socket.off("category", handleCategory);
        socket.off("label", handleLabel);
        socket.off("whatsapp", handleWhatsapp);
      };
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);



  //  ***************
  //  ** Functions **
  //  ***************
  const updateOnlineUsers = async () => {
    const { data } = await api.get("/onlineUsers");
    setOnlineUsers(data);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <SocketAuthenticatedContext.Provider value={{ getSocket, onlineUsers, updateOnlineUsers }}>
      <SocketSettings setSettings={setAllSettings} />
      {children}
    </SocketAuthenticatedContext.Provider>
  );
};

const SocketLoginProvider = ({ children }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocket();
  let userState = useRef(null);

  const { updateAllUsers } = useGlobalRegisters();



  //  ***************
  //  ** Callbacks **
  //  ***************
  const handleUserCallback = useCallback(newUserState => {
    userState.current = newUserState;
  }, []);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleUser = (data) => {
        if (!userState.current) return;

        updateAllUsers();

        if (data.action === "update" && data.user.id === userState.current.user.id && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          userState.current.setUser(data.user);
        }
      };
  
      socket.on("user", handleUser);
  
      return () => {
        socket.off("user", handleUser);
      };
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleSocketLogin = (tenantId, userId) => {
    const socket = getSocket();
    if (socket) socket.emit("login", tenantId, userId);
  }

  const handleSocketLogout = (tenantId, userId) => {
    const socket = getSocket();
    if (socket) socket.emit("logout", tenantId, userId);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <SocketLoginContext.Provider value={{ handleSocketLogin, handleSocketLogout, handleUserCallback, getSocket }}>
      {children}
    </SocketLoginContext.Provider>
  );
};

export { SocketProvider, SocketAuthenticatedProvider, SocketLoginProvider, useSocket, useSocketAuthenticated, useSocketLogin };