import { useEffect, useReducer } from "react";

import { useSocket } from "../../context/Socket/SocketContext";
import api from "../../services/api";

import { useGlobalRegisters } from "../../context/GlobalRegisters/GlobalRegistersContext";

const reducer = (state, action) => {
	if (action.type === "LOAD_WHATSAPPS") {
		const whatsApps = action.payload;
		return [...whatsApps];
	}


	if (action.type === "UPDATE_WHATSAPPS") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);

		if (whatsAppIndex !== -1) {
			state[whatsAppIndex] = whatsApp;
			return [...state];
		} else {
			return [whatsApp, ...state];
		}
	}

	if (action.type === "UPDATE_SESSION") {
		const whatsApp = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === whatsApp.id);
		if (whatsAppIndex !== -1) {
			state[whatsAppIndex].status = whatsApp.status;
			state[whatsAppIndex].updatedAt = whatsApp.updatedAt;
			state[whatsAppIndex].qrcode = whatsApp.qrcode;
			state[whatsAppIndex].retries = whatsApp.retries;
			state[whatsAppIndex].phoneNumber = whatsApp.phoneNumber;
			return [...state];
		} else { return [...state]; }
	}

	if (action.type === "UPDATE_MESSAGES_IMPORTATION") {
		const messagesImportationStatus = action.payload;
		const whatsAppIndex = state.findIndex(s => s.id === messagesImportationStatus.whatsappId);
		if (whatsAppIndex !== -1) {
			state[whatsAppIndex].importationStatus = messagesImportationStatus.status;
			return [...state];
		} else { return [...state]; }
	}

	if (action.type === "DELETE_WHATSAPPS") {
		const whatsAppId = action.payload;

		const whatsAppIndex = state.findIndex(s => s.id === whatsAppId);
		if (whatsAppIndex !== -1) {
			state.splice(whatsAppIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useWhatsApps = () => {
	const [whatsApps, dispatch] = useReducer(reducer, []);

	const { getSocket } = useSocket();

	const { whatsapps, isLoading } = useGlobalRegisters();

	useEffect(() => {
		if (isLoading) return;
		dispatch({ type: "LOAD_WHATSAPPS", payload: whatsapps });
	}, [isLoading, whatsapps]);

	useEffect(() => {
		const socket = getSocket();

		if (socket) {
			const handleUpdateWhatsapp = (data) => {
				if ((data.action === "update") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
				}
			};
	
			const handleDeleteWhatsapp = (data) => {
				if ((data.action === "delete") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
				}
			};
	
			const handleWhatsappSession = (data) => {
				if ((data.action === "update") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "UPDATE_SESSION", payload: data.session });
				}
			};
	
			const handleWhatsappMessagesImportation = (data) => {
				if ((data.action === "update") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "UPDATE_MESSAGES_IMPORTATION", payload: data.messagesImportationStatus });
				}
			};
	
			socket.on("whatsapp", handleUpdateWhatsapp);
			socket.on("whatsapp", handleDeleteWhatsapp);
			socket.on("whatsappSession", handleWhatsappSession);
			socket.on("whatsappMessagesImportation", handleWhatsappMessagesImportation);
	
			return () => {
				socket.off("whatsapp", handleUpdateWhatsapp);
				socket.off("whatsapp", handleDeleteWhatsapp);
				socket.off("whatsappSession", handleWhatsappSession);
				socket.off("whatsappMessagesImportation", handleWhatsappMessagesImportation);
			};
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { whatsApps, isLoading };
};

export default useWhatsApps;
